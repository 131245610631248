import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import { Link, useNavigate } from "react-router-dom";
import Container from "../components/Container";
import CustomInput from "../components/CustomInput";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { forgotPwd } from "../features/user/userSlice";
const emailSchema = yup.object({
  email: yup.string().email("Entrez un email valide").required("L'email est obligatoire")
});
const Forgotpassword = () => {
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: emailSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(forgotPwd(values)).then((data) => {  resetForm() })
     
  }
});
  return (
    <>
      <Meta title={"Réinitialiser votre mot de passe"} />
      <BreadCrumb title="Réinitialiser votre mot de passe" />
      <Container class1="login-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="auth-card">
              <h3 className="text-center mb-3">Réinitialiser votre mot de passe</h3>
              <p className="text-center mt-2 mb-3">
              Nous vous enverrons un e-mail pour réinitialiser votre mot de passe.
              </p>
              <form action="" className="d-flex flex-column gap-15" onSubmit={formik.handleSubmit}>
              <CustomInput type="email" name="email" placeholder="Email" 
                 value={formik.values.email} 
                 onChange={formik.handleChange("email")} 
                 onBlur={formik.handleBlur("email")}/>
                 <div className="error text-center">
                  {
                    formik.touched.email && formik.errors.email
                  }
                </div>

                <div>
                  <div className="mt-3 d-flex justify-content-center flex-column gap-15 align-items-center">
                    <button className="button border-0" type="submit">
                      Envoyer
                    </button>
                    <Link to="/login">Annuler</Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Forgotpassword;

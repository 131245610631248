import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import Container from "../components/Container";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserProductWishlist } from "../features/user/userSlice";
import { addToWishlist } from "../features/products/productSlice";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { login_url } from "../utils/axiosConfig";

const Wishlist = () => {
  const dispacth = useDispatch();
  useEffect(()=> {
    getUserWishFromDb();
  },[]);
  const getUserWishFromDb = () => {
   dispacth(getUserProductWishlist());
  };
  const wishlistState = useSelector((state) => state.auth.wishlist);
  const isLoading = useSelector((state) => state.auth.isLoading)

  const removeFromWishlist = (id) => {
    dispacth(addToWishlist(id));
    setTimeout(() => {
      dispacth(getUserProductWishlist());
    }, 300)
  }
  return (
    <>
      <Meta title={"Mes favoris"} />
      <BreadCrumb title="Mes favoris" />
      <Container class1="wishlist-wrapper home-wrapper-2 py-5">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <div className="row">
          {wishlistState?.length===0 && <div className="text-center fs-3">Aucun produit dans la liste des favoris</div>}
          {
            wishlistState?.map((item, index)=> {
              return  (
                <div className="col-3" key={index}>
                <div className="wishlist-card position-relative">
                  <img onClick={() => {removeFromWishlist(item?.product.id)}}
                    src="images/cross.svg"
                    alt="cross"
                    className="position-absolute cross img-fluid"
                  />
                  <div className="wishlist-card-image">
                    <img
                      src={`${login_url}${item?.product.imageUrl}`}
                      className="img-fluid w-100"
                      alt={item?.product.title}
                    />
                  </div>
                  <div className="py-3 px-3">
                    <h5 className="title">
                      {item?.product.title}
                    </h5>
                    <h6 className="price">{item?.product.price} Ar</h6>
                  </div>
                </div>
              </div>
              )
            }) 
          }
       
        </div>
      </Container>
    </>
  );
};

export default Wishlist;

import axios from "axios";
import { base_url, config, config_init } from "../../utils/axiosConfig";

const getProducts=async(data)=> {
    const search = new URLSearchParams()
    let categories = [];
    categories.push(data?.category?.slug)
      data?.category?.child &&
        data?.category?.child.map((item)=> {
            categories.push(item.slug)
        })
        for(let key in categories) {
            search.append('category.slug[]', categories[key]);
        }
    const response = await axios.get(`${base_url}products/?${data?.brand?`brand.name=${data?.brand}&`:""}${data?.category?search.toString():""}${data.tag?`tag=${data.tag}&`:""}${data.sort?`order[${data.sort.split('-')[0]}]=${data.sort.split('-')[1]}&`:""}${data.minPrice?`price[gt]=${data.minPrice}&`:""}${data.maxPrice?`price[lt]=${data.maxPrice}&`:""}`,config_init);
    
    if(response.data) {
        // return response.data["hydra:member"];
        return response.data;
    }
}
const getSingleProduct=async(slug)=> {
    const response = await axios.get(`${base_url}produit/${slug}`);
    if(response.data) {
        // return response.data["hydra:member"];
        return response.data;
    }
}

const addToWishlist=async(prodId)=> {
    const response = await axios.put(`${base_url}product/${prodId}/wishlist`, {prodId}, config);
    if(response.data) {
        return response.data;
    }
}
const getShippingPrice=async(shipData)=> {
    const response = await axios.post(`${base_url}ship/`,shipData);
    if(response.data) {
        return response.data;
    }
}
const rateProduct=async(data)=> {
    const token = localStorage.getItem("customer") ? JSON.parse(localStorage.getItem("customer")):null;
    const config = {
        headers: {}
    }
    const config_header = {
        headers: {
            Authorization: `Bearer ${
                token.token !== null ? token.token : ""
            }`,
            Accept: "application/json"
           
        }
    }
    const response = await axios.post(`${base_url}product/rating`, {data}, config_header);
    if(response.data) {
        return response.data;
    }
}
const getSpecialProducts=async()=> {
    const response = await axios.get(`${base_url}product/special`);
    if(response.data) {
        return response.data;
    }
}
export const productService={
    getProducts,
    addToWishlist,
    getSingleProduct,
    getShippingPrice,
    rateProduct,
    getSpecialProducts,
}
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getCurrentUser, refreshToken } from "../features/user/userSlice";
export const PrivateRoutes = ({children}) => {
   
    const dispatch = useDispatch()
    const [redi, setRedi] = useState(true)
    const getTokenFromLocalStorage = localStorage.getItem("customer") ? JSON.parse(localStorage.getItem("customer")):null;
    const refresh_token = (getTokenFromLocalStorage!==null) ? {refresh_token:getTokenFromLocalStorage.refresh_token}:null
    useEffect(() => {
        dispatch(getCurrentUser())
    }, [])
    const user = useSelector((state) => state?.auth?.user);
    useEffect(() => {
        if(!user) {
            dispatch(refreshToken(refresh_token)).then((data) => { data?.error ? setRedi(false) : dispatch(getCurrentUser())})
        }
    }, [])
    if(!redi) {
        return (<Navigate to='/login' replace={true} />)
    }
    // const getTokenFromLocalStorage=localStorage.getItem("customer")
    // return user !== null ? children : (<Navigate to='/login' replace={true} />)
    return user !== null ? children : children
}
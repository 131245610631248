import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import mvola from "../images/mvola.svg";
import Container from "../components/Container";
import { useFormik, Field, Form, Formik, useFormikContext} from "formik";
import * as yup from 'yup';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToAdress, addToOrder, getCurrentUserShipping, processCheckPaiementOrder, processPaiementOrder } from "../features/user/userSlice";
import Modal from "react-bootstrap/Modal";
import { getShippingPrice } from "../features/products/productSlice";
import { useEffect } from "react";
import axios from "axios";
import { base_url, login_url } from "../utils/axiosConfig";
import { toast } from "react-toastify";
import { useRef } from "react";
import Placeholder from "react-bootstrap/Placeholder";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Badge } from "react-bootstrap";
import Select from "react-select";


const phoneRegex = /^\(?([0-9]{1})\)?[-. ]?(34)[-. ]?([0-9]{7})$/;
const shippingSchema = yup.object({
  firstname: yup.string().required("Le prenom est obligatoire"),
  lastname: yup.string().required("Le nom est obligatoire"),
  phone: yup.string().matches(phoneRegex,'Le numero doit etre de forme 0343500003').required('Numero invalide. Veuillez entrer votre numero pour le paiement'),
  address: yup.string().required("L'adresse est obligatoire"),
  city: yup.string().required("Le Ville est obligatoire"),
  pincode: yup.string().required("Le code postal est obligatoire"),
  zone: yup.string().required("La zone est obligatoire"),
});
const marchandSchema = yup.object({
  marchand: yup.string().required("Veuillez selectionner parmis ces mode de paiement"),
});

const phoneSchema = yup.object({
  number_payment: yup.string().matches(phoneRegex,'Le numero doit etre de forme 0343500003').required('Numero invalide. Veuillez entrer votre numero pour le paiement'),
});


const Checkout = ({cart, updateCart}) => {
  const dispatch = useDispatch()
  let subtitle;
  const total = cart.reduce((acc , productType) => acc + productType.qte * productType.price,0)
  const authState = useSelector(state =>state.auth)
  const [shippingInfo, setShippingInfo] = useState()
  const navigate = useNavigate();
  const [zones, setZones] = useState(null)
  const [isForm, setIsForm] = useState('init')
  const [shippingPrice, setShippingPrice] = useState(0)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [isToggled, setToggle] = useState(false)
  const [methodPayement, setMethodPayement] = useState()
  const [selectedOption, setSelectedOption] = useState(null);
  const handleClose = () => setIsOpen(false);
  const handleShow = () => setIsOpen(true);
  const getShipAmount = (value) => {
    dispatch(getShippingPrice({cart:cart,zone:value})).then((data)=>setShippingPrice(data.payload ? data.payload:0))
}

useEffect(() => {
  async function getZone() {
    let data = [];
    const response = await  axios.get(`${base_url}zones`);
    for(let index =0; index < response.data.length; index++) {
      const element = response.data[index]
      data.push({value:element.id, label:element?.name})
    }
    setZones(data)
  }
  getZone()
}, [])
useEffect(() => {
  const zone = authState?.shipping ? authState?.shipping.zone:authState?.user?.ship[0]?.zone.match(/\d+/)[0]
  setSelectedOption(zone)
  getShipAmount(zone)
}, [authState])
const handleChange = (e) => {
  getShipAmount(e.target.value)
  setSelectedOption(e.target.value);
  // setFieldValue('rateAmount', calculatedAmount)
}

const handleToogle = () => {
  setToggle(!isToggled)
}
const handleClick= () => {

}
const formRef = useRef();

const handleSubmit = () => {
  if (formRef.current) {
    formRef.current.handleSubmit()
  }
}
// useEffect(() => {
//   if (authState.isOrderProcess===false) {
//     clearInterval(int);
//     navigate("/thank-you")
// }
// }, [authState])

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  const formik = useFormik({
    initialValues: {
      firstname: "",
  lastname: "",
  address: "",
  city: "",
  pincode: "",
  phone: "",
  zone: "",
    },
    validationSchema: shippingSchema,
    onSubmit: values => {
      // dispatch(addToAdress(values)).then((data)=> {if(!data.error) setIsOpen(true);
      dispatch(addToAdress(values))
      // handleShow()
    },
  });
  const formikPay = useFormik({
    initialValues:{
      number_payment: '',
    },
    validationSchema:phoneSchema,
    onSubmit: values => {
      setIsForm('pending')
      const data_order = {
        totalPaid: total.toString(),
        number_payment: values.number_payment,
        mode_payment: methodPayement,
        cart: cart
      }
      dispatch(addToOrder(data_order)).then((data)=> { 
        if (data.error) {
          navigate("/login")
      } else { 
        dispatch(processPaiementOrder(data.payload)).then((res) => { 
          if(res.error) {
            toast.error("Erreur lors du paiement")
        } else {
          var int = setInterval((function() {
            dispatch(processCheckPaiementOrder(res.payload)).then((resp) => { if(resp.payload==='completed') {
              clearInterval(int); 
              setIsForm('done')
              localStorage.removeItem('cart')
              setTimeout(() => {navigate("/thank-you")}, 3000)
              
            }})
          
        }), 4000);
        if (authState.isOrderProcess===false) {
          clearInterval(int);
      }
      }
      })
    }
    })
    }
  });

  return (
    <>
      <Container class1="checkout-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-7 col-md-7">
            <div className="checkout-left-data">
              <h3 className="website-name">Adeen Store</h3>
              <nav
                style={{ "--bs-breadcrumb-divider": ">" }}
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link className="text-dark total-price" to="/cart">
                      Cart
                    </Link>
                  </li>
                  &nbsp; /&nbsp;
                  <li
                    className="breadcrumb-ite total-price active"
                    aria-current="page"
                  >
                    Information
                  </li>
                  &nbsp; /
                  <li className="breadcrumb-item total-price active">
                    Livraison
                  </li>
                  &nbsp; /
                  <li
                    className="breadcrumb-item total-price active"
                    aria-current="page"
                  >
                    Paiement
                  </li>
                </ol>
              </nav>
              <h4 className="title total">Contact Information</h4>
              <p className="user-details total">
                {
                  authState?.shipping ?   <>
                  <p>{authState?.shipping?.lastname} {authState?.shipping.firstname} ({authState?.user?.email})</p>
                  <p>{authState?.shipping.address}</p>
                  <p>{authState?.shipping.city} - {authState?.shipping.pincode}</p>
                  <p>{authState?.shipping.phone}</p>
                  </>
                  :
               <>
                <p>{authState?.user?.ship[0]?.lastname} {authState?.user?.ship[0]?.firstname} ({authState?.user?.email})</p>
                <p>{authState?.user?.ship[0]?.address}</p>
                <p>{authState?.user?.ship[0]?.city} - {authState?.user?.ship[0]?.pincode}</p>
                <p>{authState?.user?.ship[0]?.phone}</p>
                
                </>
              }

              
              </p>
              {
                isToggled===true || (!authState?.user?.ship[0] && !authState?.shipping) ?
                <div className="">
                <h4 className="mb-3">Adresse de livraison</h4>
                <form onSubmit={formik.handleSubmit}
                action=""
                className="d-flex gap-15 flex-wrap justify-content-between"
              >
                {/* <div className="w-100">
                  <select name="" className="form-control form-select" id="">
                    <option value="" selected disabled>
                      Select Country
                    </option>
                  </select>
                </div> */}
                <div className="flex-grow-1">
                  <input
                    type="text"
                    placeholder="Prenom"
                    name="firstname"
                    className="form-control"
                    value={formik.values.firstname }
                    onChange={formik.handleChange("firstname")}
                    onBlur={formik.handleBlur("firstname")}
                  />
                  <div className="error ms-2 my-1">
                    {
                      formik.touched.firstname && formik.errors.firstname
                    }
                  </div>
                </div>
                <div className="flex-grow-1">
                  <input
                    type="text"
                    name="lastname"
                    placeholder="Nom"
                    className="form-control"
                    value={formik.values.lastname }
                    onChange={formik.handleChange("lastname")}
                    onBlur={formik.handleBlur("lastname")}
                  />
                   <div className="error ms-2 my-1">
                    {
                      formik.touched.lastname && formik.errors.lastname
                    }
                  </div>
                </div>
                <div className="w-100">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Telephone"
                    value={formik.values.phone}
                    onChange={formik.handleChange("phone")}
                    onBlur={formik.handleBlur("phone")}
                    className="form-control"
                  />
                   <div className="error ms-2 my-1">
                    {
                      formik.touched.phone && formik.errors.phone
                    }
                  </div>
                </div>
                {/* <div className="w-100"> */}
                <div className="flex-grow-1">
                  <input
                    type="text"
                    name="address"
                    placeholder="Adresse"
                    value={formik.values.address }
                    onChange={formik.handleChange("address")}
                    onBlur={formik.handleBlur("address")}
                    className="form-control"
                  />
                   <div className="error ms-2 my-1">
                    {
                      formik.touched.address && formik.errors.address
                    }
                  </div>
                </div>
               
                <div className="flex-grow-1">
                  <input
                    type="text"
                    placeholder="Ville"
                    name="city"
                    value={formik.values.city }
                    onChange={formik.handleChange("city")}
                    onBlur={formik.handleBlur("city")}
                    className="form-control"
                  />
                    <div className="error ms-2 my-1">
                    {
                      formik.touched.city && formik.errors.city
                    }
                  </div>
                </div>
              
                
                <div className="flex-grow-1">
                  <input
                    type="text"
                    placeholder="Code postal"
                    name="pincode"
                    className="form-control"
                    value={formik.values.pincode }
                    onChange={formik.handleChange("pincode")}
                    onBlur={formik.handleBlur("pincode")}
                  />
                  <div className="error ms-2 my-1">
                    {
                      formik.touched.pincode && formik.errors.pincode
                    }
                  </div>
                </div>
                <div className="flex-grow-1">
                  <Select
                    placeholder="Zone"
                    name="zone"
                    // className="form-control"
                    // options={zones}
                    // defaultValue={{ label: '', value: authState?.user?.ship[0].zone.match(/\d+/)[0] }}
                    // value={selected ? selected:formik.values.zone}
                    defaultValue={selectedOption}
                    onChange={(zoneSelected) => {
                      getShipAmount(zoneSelected.value)
                      formik.setFieldValue('zone', zoneSelected.value)
                    }
                    }
                    options={zones}
                  />
                     {/* <option value="">- Selectionnez zone -</option>
                    { 
                    zones && zones?.map((item, index) => {
                      return (
                        <option value={item.id} key={index} >{item.name}</option>
                      )
                    })
                    }
               
                  </select> */}

                  <div className="error ms-2 my-1">
                    {
                      formik.touched.zone && formik.errors.zone
                    }
                  </div>
                </div>
                
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center">
                    <Link to="/cart" className="text-dark">
                      <BiArrowBack className="me-2" />
                      Retourner au panier
                    </Link>
                    <Link to="/cart" className="button">
                      Continuer vos achats
                    </Link>
                    <button className="button" type="submit">Commander</button>
                  </div>
                </div>
              </form></div>:<div>
              <Formik
      initialValues={{
        marchand: '',
      }}
      validationSchema={marchandSchema}
      onSubmit={async (values) => {
        await new Promise((r) => setTimeout(r, 500));
        setIsOpen(true);
        setIsForm('init');
        setMethodPayement(values.marchand);
      }}
    >
      {({ values, errors, touched }) => (
                <Form><p>
                  {/* <label>
              <Field type="radio" name="marchand" value="livraison"  />
              A la Livraison
            </label> */}
            <label>
              <Field type="radio" name="marchand" value="mvola" />
             <img src={mvola} alt="mvola" width={50}/>
            </label>
            <div className="error ms-2 my-1">
            {errors.marchand && touched.marchand ? (
            <div>{errors.marchand}</div>
          ) : null}
          </div>
            </p> <Link onClick={handleToogle} className="button">
            Changer mon adresse de livraison
                    </Link>{'\u00A0'}<button  class="button" type="submit">Paiement</button></Form>)}
    </Formik></div>
              }
              
              
            </div>
          </div>
          <div className="col-5 col-md-5">
          <div className="border-bottom py-4">
            {
              cart && cart.map(({productId, productTitle, price, qte, image}, index)=> (
<div key={index} className="d-flex gap-10 mb-2 align-align-items-center">
                <div className="w-75 d-flex gap-10">
                  <div className="w-25 position-relative badge-product">
                  <Badge pill  bg="danger"> {qte}</Badge>
                    {/* <span
                      style={{ top: "-10px", right: "2px" }}
                      className="badge bg-secondary text-white rounded-circle p-2 position-absolute"
                    >
                    {qte}
                    </span> */}
                    <img className="img-fluid" src={`${login_url}${image}`} alt="product" />
                  </div>
                  <div>
                    <h5 className="total-price">{productTitle}</h5>
                    {/* <p className="total-price">s / #agfgfd</p> */}
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="total">{price} Ar</h5>
                </div>
              </div>
              ))
            }
            
              

            </div>
            <div className="border-bottom py-4">
              <div className="d-flex justify-content-between align-items-center">
                <p className="total">Total Ht</p>
                <p className="total-price">{total} Ar</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0 total">Livraison</p>
                <p className="mb-0 total-price">{shippingPrice} Ar</p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bootom py-4">
              <h4 className="total">Total TTC</h4>
              <h5 className="total-price">{(parseFloat(total)+parseFloat(shippingPrice)).toFixed(2)} Ar</h5>
            </div>
          </div>
        </div>
        <div
      className="modal"
      style={{ display: 'block', position: 'initial' }}
    >
       
        <Modal show={modalIsOpen}>
        {
                isForm==='init' ? (
        <form onSubmit={formikPay.handleSubmit}>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Paiement securise</Modal.Title>
        </Modal.Header>

        <Modal.Body>
                  <div>
<div className="flex-grow-1">
              <input type="text" name="number_payment"  placeholder="Numero" className="form-control" onBlur={formikPay.handleBlur("number_payment")} onChange={formikPay.handleChange("number_payment")}
              value={formikPay.values.number_payment} />
                    </div>
            <div className="error ms-2 my-1">
            {formikPay.errors.number_payment && formikPay.touched.number_payment ? (
            <div>{formikPay.errors.number_payment}</div>
          ) : null}
          </div>
            <button  class="button" type="submit">Payer</button>
            </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
        </form>): <><Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Paiement securise</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isForm==='pending' ? (
            <>
        <Placeholder animation="glow">
        <Placeholder xs={12} size="lg" />
      </Placeholder>
      <Placeholder animation="glow">
        <Placeholder xs={12} size="sm" />
      </Placeholder>
      <Placeholder animation="glow">
        <Placeholder xs={12} size="xs" />
      </Placeholder></>
          ):(
            <div class="success-animation">
<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
</div>
          )}
      </Modal.Body>
        <Modal.Footer>
        </Modal.Footer></>
}
      </Modal>
     
      </div>
      </Container>
    </>
  );
};

export default Checkout;

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import BlogCard from "../components/BlogCard";
import ProductCard from "../components/ProductCard";
import SpecialProduct from "../components/SpecialProduct";
import Container from "../components/Container";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../features/blogs/blogSlice";
import ReactStars from "react-rating-stars-component";
import prodcompare from "../images/prodcompare.svg";
import wish from "../images/wish.svg";
import addcart from "../images/add-cart.svg";
import view from "../images/view.svg";
import { toast } from "react-toastify";
import { login_url } from "../utils/axiosConfig";
// import { services } from "../utils/Data";
import moment from "moment";
import { getAllProducts, addToWishlist, getAllSpecialProducts } from "../features/products/productSlice";
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { getAllCategories } from "../features/categories/categoriesSlice";
import { getAllBrands } from "../features/brands/brandsSlice";
import { Backdrop, CircularProgress } from "@mui/material";

const spanStyle = {
  padding: '20px',
  background: 'transparent',
  color: '#000000'
}

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '400px'
}
const slideImages = [
  {
    url: 'images/main-banner-1.jpg',
    caption: 'SUPERCHARGED FOR PROS.',
    description: 'A partir de 1 000 000 Ar',
    title: 'iPhone 15 Pro.',
    link: 'https://.',
    color: '#ffffff'
  },
  {
    url: 'images/main-banner-2.jpg',
    caption: 'SUPERCHARGED FOR PROS.',
    description: 'A partir de 1 000 000 Ar',
    title: 'iPad S13+ Pro.',
    link: 'https://.',
    color: '#000000'
  },
  {
    url: 'images/main-banner.jpg',
    caption: 'SUPERCHARGED FOR PROS.',
    description: 'A partir de 1 000 000 Ar',
    title: 'iPad S13+ Pro.',
    link: 'https://.',
    color: '#000000.'
  },
];

const Home = ({categoriesStateNav, cart, updateCart}) => {
  function addToCart(productId, productTitle, quantity, color, price, image) {
		const currentProductSaved = cart.find((product) => product.productId === productId)
		if (currentProductSaved) {
			const cartFilteredCurrentProduct = cart.filter(
				(product) => product.productId !== productId
			)
			updateCart([
				...cartFilteredCurrentProduct,
				{ productId, productTitle, price, qte: currentProductSaved.qte + quantity, image}
			])
		} else {
			updateCart([...cart, { productId, productTitle,  price, qte: quantity, image }])
		}
    toast.success(productTitle+' ajoute dans le panier');
	}
  const blogState = useSelector((state) => state?.blog?.blog);
  const productState = useSelector((state) => state?.product?.product);
  // const categoriesState = useSelector((state) => state?.categories?.categories);
  const brandsState = useSelector((state) => state?.brands?.brands);
  const specialState = useSelector((state) => state?.product?.specialproducts);
  const isLoading = useSelector((state) => state.product.isLoading)
  const navigate=useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState(null)
  useEffect(()=> {
    getBlogs();
  },[]);
  const getBlogs = () => {
   dispatch(getAllBlogs());
  };
  useEffect(()=> {
    getBrands();
  },[]);
  const getBrands = () => {
   dispatch(getAllBrands());
  };
  useEffect(()=> {
    getProducts();
    getSpecialProducts();
  },[]);
  const getSpecialProducts= () => {
   dispatch(getAllSpecialProducts());
  };
  // useEffect(()=> {
  //   dispatch(getAllCategories());
  // },[]);
  const addToWish = (id) => {
    dispatch(addToWishlist(id))
     }
  const getProducts = () => {
   dispatch(getAllProducts({}));
  };
  return (
    <>
      <Container class1="home-wrapper-1 py-5">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <div className="row">
          <div className="col-md-6 col-sm-12 slider-block">
            <div className="row position-relative ">
              {/* <img
                src="images/main-banner-1.jpg"
                className="img-fluid rounded-3"
                alt="main banner"
              />
              <div className="main-banner-content position-absolute">
                <h4>SUPERCHARGED FOR PROS.</h4>
                <h5>iPad S13+ Pro.</h5>
                <p>From $999.00 or $41.62/mo.</p>
                <Link className="button">BUY NOW</Link>
              </div> */}
              <Fade>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
               <img style={{ width: '100%' }} src={slideImage.url} className="img-fluid rounded-3" />
                <div className="main-banner-content position-absolute">
                <h4 style={{color: slideImage.color}}>{slideImage.caption}</h4>
                <h5 style={{color: slideImage.color}}>{slideImage.title}</h5>
                <p style={{color: slideImage.color}}>{slideImage.description}</p>
                <Link className="button" to={slideImage.url}>ACHETER</Link>
              </div>
              
            </div>
          ))} 
        </Fade>
            </div>
          </div>
          <div className="col-md-6 small-ban">
            {/* <div className="d-flex flex-wrap gap-10 justify-content-between align-items-center"> */}
            <div className="row ads-block">
              <div className="col-md-6 col-sm-6 col-xs-12 position-relative">
                <img
                  src="images/catbanner-01.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h4>Best Sake</h4>
                  <h5>iPad S13+ Pro.</h5>
                  <p>
                    From $999.00 <br /> or $41.62/mo.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12 position-relative">
                <img
                  src="images/catbanner-02.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h4>NEW ARRIVAL</h4>
                  <h5>But IPad Air</h5>
                  <p>
                    From $999.00 <br /> or $41.62/mo.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12 position-relative ">
                <img
                  src="images/catbanner-03.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h4>NEW ARRIVAL</h4>
                  <h5>But IPad Air</h5>
                  <p>
                    From $999.00 <br /> or $41.62/mo.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12 position-relative ">
                <img
                  src="images/catbanner-04.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h4>NEW ARRIVAL</h4>
                  <h5>But IPad Air</h5>
                  <p>
                    From $999.00 <br /> or $41.62/mo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container class1="home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="servies d-flex align-items-center justify-content-between">
              {/* {services?.map((i, j) => {
                return (
                  <div className="d-flex align-items-center gap-15" key={j}>
                    <img src={i.image} alt="services" />
                    <div>
                      <h6>{i.title}</h6>
                      <p className="mb-0">{i.tagline}</p>
                    </div>
                  </div>
                );
              })} */}
            </div>
          </div>
        </div>
      </Container>
      <Container class1="home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="categories d-flex justify-content-between flex-wrap align-items-center">
            {
                    categoriesStateNav && categoriesStateNav.map((item, index)=> {
                      return (
                      <div key={index} className="d-flex gap align-items-center">
                <div>
                
                  <h6><Link to={`/boutique/${item.slug}`}>{item.name}</Link></h6>
                  {/* <p>10 Items</p> */}
                </div>
                <Link to={`/boutique/${item.slug}`}><img src={`${login_url}images\/category\/${item?.thumbs}`} alt={item.name} width={110} /></Link>
              
              </div>
                      )
                    })
                  }
            </div>
          </div>
        </div>
      </Container>
      <Container class1="featured-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Nouveaux produits</h3>
          </div>
          {/* <div className="row"> */}
          {
            productState && productState?.map((item, index) => {
              if(item?.tags==='collection') {
                return (
                  <div key={item.id} className="col-md-3"
        >
          <Link
            // to={`${
            //   location.pathname == "/"
            //     ? "/product/:id"
            //     : location.pathname == "/product/:id"
            //     ? "/product/:id"
            //     : ":id"
            // }`}
            className="product-card position-relative"
          >
            <div className="wishlist-icon position-absolute">
              <button className="border-0 bg-transparent" onClick={(e) => {addToWish(item?.id)}}>
                <img src={wish} alt="wishlist" />
              </button>
            </div>
            <div className="product-image">
              <img src={`${login_url}${item?.imageUrl}`} className="img-fluid mx-auto" alt="product image" width={160} />
              <img src={`${login_url}${item?.imageUrl}`} className="img-fluid mx-auto" alt="product image" width={160} />
              {/* <img src={watch2} className="img-fluid" alt="product image" /> */}
            </div>
            <div className="product-details">
              <h6 className="brand">
                {/* {item.category.name} */}
                {/* {item.brand.name} */}
                </h6>
              <h5 className="product-title">
               {item.title}
              </h5>
              <ReactStars
                count={5}
                size={24}
                value={item?.totalrating}
                edit={false}
                activeColor="#ffd700"
              />
           
              <p className="price">{item.price} Ar</p>
            </div>
            <div className="action-bar position-absolute">
              <div className="d-flex flex-column gap-15">
                {/* <button className="border-0 bg-transparent">
                  <img src={prodcompare} alt="compare" />
                </button> */}
                <button className="border-0 bg-transparent">
                  <Link to={`/product/${item?.slug}`}>
                  <img src={view} alt="view" />
                  </Link>
                </button>
                <button className="border-0 bg-transparent">
                  <img src={addcart} onClick={()=> { addToCart(item?.id, item?.title, 1,null,item?.price, item?.imageUrl) }} alt="ajouter panier" />
                </button>
              </div>
            </div>
          </Link>
        </div>
                )
              }
              
            })
          }
        {/* </div> */}
        </div>
      </Container>

      {/* <Container class1="famous-wrapper py-5 home-wrapper-2">
        <div className="row">
        {
            productState && productState?.map((item, index) => {
              if(item?.tags==='collection') {
                return (
          <div className="col-3" key={index}>
            <div className="famous-card position-relative">
              <img
                src="images/famous-1.webp"
                className="img-fluid"
                alt="famous"
              />
              <div className="famous-content position-absolute">
                <h5>Big Screen</h5>
                <h6>Smart Watch Series 7</h6>
                <p>From $399or $16.62/mo. for 24 mo.*</p>
              </div>
            </div>
          </div>
                )
          }
            })
    }
        </div>
      </Container> */}

      <Container class1="special-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">En promo</h3>
          </div>
        </div>
        <div className="row">
          {
            specialState && specialState?.map((item, index) => {
                return (
                  <SpecialProduct key={index} id={item?.slug} title={item?.title} brand={item?.brand?.name} rating={item?.rate?.toString()} price={item.price} sold={item?.sold} quantity={item?.quantity} days={item?.days} hours={item?.hours} mins={item?.mins} secs={item?.secs} remainig={item?.remainig} picture={item?.picture}  />
                )
              
            })
          }
        </div>
      </Container>
      <Container class1="popular-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Les meilleures ventes</h3>
          </div>
        </div>
        <div className="row">
          {
            productState && productState?.map((item, index) => {
              if(item?.tags==='popular') {
                return (
                  <div key={item.id} className="col-md-3"
        >
          <div
            className="product-card position-relative"
          >
            <div className="wishlist-icon position-absolute">
              <button className="border-0 bg-transparent" onClick={(e) => {addToWish(item?.id)}}>
                <img src={wish} alt="wishlist" />
              </button>
            </div>
            <div className="product-image">
              <img src={`${login_url}${item?.imageUrl}`} className="img-fluid mx-auto" alt="product image" width={160} />
              <img src={`${login_url}${item?.imageUrl}`} className="img-fluid mx-auto" alt="product image" width={160} />
              {/* <img src={watch2} className="img-fluid" alt="product image" /> */}
            </div>
            <div className="product-details">
              <h6 className="brand">
                {/* {item.category.name} */}
                {item.brand.name}
                </h6>
              <h5 className="product-title">
               {item.title}
              </h5>
              <ReactStars
                count={5}
                size={24}
                value={item?.totalrating}
                edit={false}
                activeColor="#ffd700"
              />
           
              <p className="price">{item.price} Ar</p>
            </div>
            <div className="action-bar position-absolute">
              <div className="d-flex flex-column gap-15">
                <button className="border-0 bg-transparent">
                  <img src={prodcompare} alt="compare" />
                </button>
                <button className="border-0 bg-transparent">
                <img onClick={ () => navigate("/product/"+item?.slug)} src={view} alt="view pp" />
                </button>
                <button className="border-0 bg-transparent">
                  <img src={addcart} alt="addcart" onClick={()=> { addToCart(item?.id, item?.title, 1,null,item?.price, item?.imageUrl) }} />
                </button>
              </div>
            </div>
          </div>
        </div>
                )
              }
              
            })
          }
        </div>
      </Container>
      <Container class1="marque-wrapper home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="marquee-inner-wrapper card-wrapper">
              <Marquee className="d-flex">
              {
            brandsState && brandsState?.map((item, index) => {
              return (
                <div className="mx-4 w-25" key={index}>
                  <img src={`${login_url}images\/brands\/${item?.thumbs}`} alt="brand" width={100}/>
                </div>
              )
            })
          }
              </Marquee>
            </div>
          </div>
        </div>
      </Container>

      <Container class1="blog-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Actualite</h3>
          </div>
        </div>
        <div className="row">
        {
                blogState && blogState?.map((item, index)  => {
                  return (
                    <div className="col-md-3" key={index}>
                    <BlogCard id={item?.id} title={item?.title} description={item?.content} date={moment(item?.createdAt).format('D MM YYYY')} image={item?.thumbs}/>
                  </div>
                  )
                })
              }
        </div>
      </Container>
    </>
  );
};

export default Home;

import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "../components/Container";
import CustomInput from "../components/CustomInput";
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useEffect } from "react";
import { tokenValid, updatePass } from "../features/user/userSlice";
const passwordSchema = yup.object({
  password: yup.string().required("L'password est obligatoire").matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    "Doit contenir 8 Characters au minimum, un Majuscule, une lettre minuscule et un nombre"
  ),
  confpassword: yup.string().required("Mot de passe obligatoire").oneOf([yup.ref("password"), null], "Mot de passe non identique")
});
const Resetpassword = () => {
  const location = useLocation()
  const getToken = location.pathname.split('/')[2]
  const dispatch = useDispatch();
  const navigate = useNavigate()
  useEffect(()=> {
    dispatch(tokenValid(getToken)).then((data) =>  { if(data.error) navigate('/login')
  })},
  []);
  const formik = useFormik({
    initialValues: {
      password: '',
      confpassword: '',
      token: getToken,
    },
    validationSchema: passwordSchema,
    onSubmit: values => {
      dispatch(updatePass(values))
    },
  });
  console.log(getToken)
  return (
    <>
      <Meta title={"Réinitialiser le mot de passe"} />
      <BreadCrumb title="Réinitialiser le mot de passe" />
      <Container class1="login-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="auth-card">
              <h3 className="text-center mb-3">Réinitialiser le mot de passe</h3>
              <form action="" className="d-flex flex-column gap-15" onSubmit={formik.handleSubmit} autoComplete="off">
                <CustomInput
                  type="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="off"
                  value={formik.values.password} 
                  onChange={formik.handleChange("password")} 
                  onBlur={formik.handleBlur("password")}/>
                  <div className="error">
                   {
                     formik.touched.password && formik.errors.password
                   }
                 </div>
                <CustomInput
                  type="password"
                  name="confpassword"
                  placeholder="Confirm Password"
                  value={formik.values.confpassword} 
                  onChange={formik.handleChange("confpassword")} 
                  onBlur={formik.handleBlur("confpassword")}/>
                  <div className="error">
                   {
                     formik.touched.confpassword && formik.errors.confpassword
                   }
                 </div>
                 <CustomInput
                  type="hidden"
                  name="token"
                  value={getToken}
                  onChange={getToken} 
                  onBlur={getToken}/>
                <div>
                  <div className="mt-3 d-flex justify-content-center gap-15 align-items-center">
                    <button className="button border-0">Reinitialiser</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Resetpassword;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { categoriesService} from "./categoriesService";
export const getAllCategories=createAsyncThunk("category/get", async (thunkAPI) => {
    try{
        return categoriesService.getCategories();
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
} );

export const getAllCategoriesNav=createAsyncThunk("category/getnav", async (thunkAPI) => {
    try{
        return categoriesService.getCategoriesNav();
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
} );
export const getCategory=createAsyncThunk("get-category", async (slug, thunkAPI) => {
    try{
        return categoriesService.getACategory(slug);
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
} );

const categoriesState = {
    categories: "",
    categoriesNav: "",
    category: "",
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:""
}

export const categoriesSlice = createSlice({
    name: "categories",
    initialState: categoriesState,
    reducers:{},
    extraReducers:(builder) => {
        builder.addCase(getAllCategories.pending,(state)=> {
            state.isLoading=true;
        }).addCase(getAllCategories.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.categories=action.payload;
        } ).addCase(getAllCategories.rejected, (state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        }).addCase(getAllCategoriesNav.pending,(state)=> {
            state.isLoading=true;
        }).addCase(getAllCategoriesNav.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.categoriesNav=action.payload;
        } ).addCase(getAllCategoriesNav.rejected, (state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        }).addCase(getCategory.pending,(state)=> {
            state.isLoading=true;
        }).addCase(getCategory.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.category=action.payload;
        }).addCase(getCategory.rejected, (state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
    }
})
export default categoriesSlice.reducer
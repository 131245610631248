import React from "react";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import Container from "../components/Container";
import CustomInput from "../components/CustomInput";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { loginUser, getCurrentUser } from "../features/user/userSlice";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const loginSchema = yup.object({
  email: yup.string().email("Entrez un email valide").required("L'email est obligatoire"),
  password: yup.string().required("Mot de passe obligatoire")
});
const Login = () => {
  const authState = useSelector(state=>state.auth)
  const dispatch = useDispatch();
  const isLoading = authState.isLoading
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: values => {
      dispatch(loginUser(values)).then((data) => { if (!data.error) { dispatch(getCurrentUser()).then((data) => {setTimeout(
        navigate('/'), 300
      )}) }
      })
    },
  });
  return (
    <>
      <Meta title={"Login"} />
      <BreadCrumb title="Login" />

      <Container class1="login-wrapper py-5 home-wrapper-2">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <div className="row">
          <div className="col-md-12">
            <div className="auth-card">
              <h3 className="text-center mb-3">Login</h3>
              <form action="" className="d-flex flex-column gap-15" onSubmit={formik.handleSubmit}>
                <CustomInput type="email" name="email" placeholder="Email" 
                 value={formik.values.email} 
                 onChange={formik.handleChange("email")} 
                 onBlur={formik.handleBlur("email")}/>
                 <div className="error">
                  {
                    formik.touched.email && formik.errors.email
                  }
                </div>
                <CustomInput
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formik.values.password} 
                 onChange={formik.handleChange("password")} 
                 onBlur={formik.handleBlur("password")}/>
                 <div className="error">
                  {
                    formik.touched.password && formik.errors.password
                  }
                </div>
                <div>
                  <Link to="/forgot-password">Mot de passe oublie ?</Link>

                  <div className="mt-3 d-flex justify-content-center gap-15 align-items-center">
                    <button className="button border-0" type="submit">
                      Login
                    </button>
                    <Link to="/signup" className="button signup">
                      S'inscrire
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Login;

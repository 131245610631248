import React, { useEffect, useState } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import BlogCard from "../components/BlogCard";
import Container from "../components/Container";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../features/blogs/blogSlice";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

const Blog = () => {
  const blogState = useSelector((state) => state?.blog?.blog);
  const isLoading = useSelector((state) => state?.blog?.isLoading);

  const dispacth = useDispatch();
  useEffect(()=> {
    getBlogs();
  },[]);
  const getBlogs = () => {
   dispacth(getAllBlogs());
  };
  return (
    <>
      <Meta title={"Blogs"} />
      <BreadCrumb title="Blogs" />
      <Container class1="blog-wrapper home-wrapper-2 py-5">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <div className="row">
          <div className="col-3">
            <div className="filter-card mb-3">
              <h3 className="filter-title">Find By Categories</h3>
              <div>
                <ul className="ps-0">
                  <li>Watch</li>
                  <li>Tv</li>
                  <li>Camera</li>
                  <li>Laptop</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-9">
            <div className="row">
              {
                blogState && blogState?.map((item, index)  => {
                  return (
                    <div className="col-6 mb-3" key={index}>
                    <BlogCard id={item?.id} title={item?.title} description={item?.content} date={moment(item?.createdAt).format('D MM YYYY')} image={item?.thumbs}/>
                  </div>
                  )
                })
              }
             
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Blog;

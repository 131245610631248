import React from "react";
import ReactStars from "react-rating-stars-component";
import { Link, useLocation } from "react-router-dom";
import prodcompare from "../images/prodcompare.svg";
import wish from "../images/wish.svg";
import addcart from "../images/add-cart.svg";
import { useDispatch, useSelector } from "react-redux";
import view from "../images/view.svg";
import { addToWishlist } from "../features/products/productSlice";
import { login_url } from "../utils/axiosConfig";
import { toast } from "react-toastify";
const ProductCard = (props) => {
  const { data, grid, cart, updateCart} = props;
  function addToCart(productId, productTitle, quantity, color, price, image) {
		const currentProductSaved = cart.find((product) => product.productId === productId)
		if (currentProductSaved) {
			const cartFilteredCurrentProduct = cart.filter(
				(product) => product.productId !== productId
			)
			updateCart([
				...cartFilteredCurrentProduct,
				{ productId, productTitle, price, qte: currentProductSaved.qte + quantity, image}
			])
		} else {
			updateCart([...cart, { productId, productTitle,  price, qte: quantity, image }])
		}
    toast.success(productTitle+' ajoute dans le panier');
	}
 const dispacth = useDispatch();
 const addToWish = (id) => {
dispacth(addToWishlist(id))
 }

  let location = useLocation();
  if (!data) {
    return <p className="display">Error</p>;
  } else {
  return (
    <>
    { data?.map((item, index) => {
          return (
  <div key={item.id}
          className={` ${
            location.pathname == "/boutique" ? `gr-${grid}` : "col-md-3 col-sm-3 col-xs-12"
          } `}
        >
          <div
            className="product-card position-relative"
          >
            <div className="wishlist-icon position-absolute">
              <button className="border-0 bg-transparent" onClick={(e) => {addToWish(item?.id)}}>
                <img src={wish} alt="wishlist" />
              </button>
            </div>
            <div className="product-image">
              <img src={`${login_url}${item?.imageUrl}`} className="img-fluid mx-auto" alt="product image" width={160} />
              <img src={`${login_url}${item?.imageUrl}`} className="img-fluid mx-auto" alt="product image" width={160} />
              {/* <img src={watch2} className="img-fluid" alt="product image" /> */}
            </div>
            <div className="product-details">
              <h6 className="brand">
                {/* {item.category.name} */}
                {item?.brand?.name}
                </h6>
              <h5 className="product-title">
               {item.title}
              </h5>
              <ReactStars
                count={5}
                size={24}
                value={item?.totalrating}
                edit={false}
                activeColor="#ffd700"
              />
              <p className={`description ${grid === 12 ? "d-block" : "d-none"}`}
              dangerouslySetInnerHTML={{__html: item?.content}}>
              </p>
              <p className="price">{item.price} Ar</p>
            </div>
            <div className="action-bar position-absolute">
              <div className="d-flex flex-column gap-15">
                {/* <button className="border-0 bg-transparent">
                  <img src={prodcompare} alt="compare" />
                </button> */}
                <Link to={'/product/'+item?.slug} className="border-0 bg-transparent">
                  <img src={view} alt="view" />
                </Link>
                <button className="border-0 bg-transparent">
                  <img src={addcart} onClick={()=> { addToCart(item?.id, item?.title, 1,null,item?.price, item?.imageUrl) }} alt="ajouter panier" />
                </button>
              </div>
            </div>
          </div>
        </div>
          )
        })
      }
      
    </>
  )
    }
  }

export default ProductCard;

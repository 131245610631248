import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import Container from "../components/Container";
import * as yup from 'yup';
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../features/user/userSlice";
import { useState } from "react";
import {FaRegEdit} from "react-icons/fa";

const profilSchema = yup.object({
    firstname: yup.string().required("Prenom est obligatoire"),
    lastname: yup.string().required("Nom est obligatoire"),
    email: yup.string().email("Entrez un email valide").required("L'email est obligatoire"),
    phone: yup.string().required("Le telephone est obligatoire"),
  });
const Profile = () => {
    const dispatch = useDispatch();
    const userState = useSelector((state)=>state.auth.user)
    const [edit, setEdit] = useState(true)
  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
        firstname: userState?.firstname,
        lastname: userState?.lastname,
        phone: userState?.phone,
        email: userState?.email,
    },
    validationSchema: profilSchema,
    onSubmit: values => {
      dispatch(updateProfile(values));
      setEdit(true)
    },
  });
return (
    <>
    <BreadCrumb title="Mon profile"></BreadCrumb>
    <Container class1="cart-wrapper home-wrapper-2 py-5">
        <div className="row">
            <div className="col-12">
                <div className="d-flex justify-content-between align-items-center">
                <h1 className="my-3"> Mettre a jour le profile</h1>
                <FaRegEdit className="fs-3" onClick={()=>setEdit(false)} />
                </div>
               
            </div>
            <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
  <div className="mb-3">
    <label htmlFor="name" className="form-label">Nom</label>
    <input type="text" name="lastname" disabled={edit} className="form-control" id="name" 
    value={formik.values.lastname} 
    onChange={formik.handleChange("lastname")} 
    onBlur={formik.handleBlur("lastname")} 
    />
    <div className="error">
        {formik.touched.lastname && formik.errors.lastname}
    </div>
  </div>
  <div className="mb-3">
    <label htmlFor="prenom" className="form-label">Prenom</label>
    <input type="text" name="firstname" disabled={edit} className="form-control" id="prenom"
     value={formik.values.firstname} 
     onChange={formik.handleChange("firstname")} 
     onBlur={formik.handleBlur("firstname")} 
    />
     <div className="error">
        {formik.touched.firstname && formik.errors.firstname}
    </div>
  </div>
  <div className="mb-3">
    <label htmlFor="email" className="form-label">Email</label>
    <input type="email" className="form-control" disabled={edit} name="email" id="email" 
     value={formik.values.email} 
     onChange={formik.handleChange("email")} 
     onBlur={formik.handleBlur("email")} 
    />
     <div className="error">
        {formik.touched.email && formik.errors.email}
    </div>
  </div>
  <div className="mb-3">
    <label htmlFor="prenom" className="form-label">Telephone</label>
    <input type="text" className="form-control" disabled={edit} id="phone" name="phone" 
     value={formik.values.phone} 
     onChange={formik.handleChange("phone")} 
     onBlur={formik.handleBlur("phone")} 
    />
    <div className="error">
        {formik.touched.phone && formik.errors.phone}
    </div>
  </div>
  {
    edit===false ? <button type="submit" className="btn btn-primary">Enregistrer</button>:''
  }
 
  
</form>
            </div>
        </div>
    </Container>
    </>
)
}

export default Profile
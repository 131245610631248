import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { authService } from "./userService";
export const registerUser=createAsyncThunk("auth/register", async (userData,thunkAPI) => {
    try{
        return authService.register(userData)
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
} );
export const loginUser=createAsyncThunk("auth/login", async (userData,thunkAPI) => {
    try{
        return authService.login(userData)
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
} );
export const getUserProductWishlist = createAsyncThunk("user/wishlist", async(thunkAPI) => {
try {
    return await authService.getUserWishlist()
} catch(error){
    return thunkAPI.rejectWithValue(error)
}
}); 

export const addToOrder = createAsyncThunk("order/add", async(cartData, thunkAPI) => {
    try {
        return await authService.addToOrder(cartData)
    } catch(error){
        return thunkAPI.rejectWithValue(error)
    }
    });
export const addToAdress = createAsyncThunk("ship/add", async(cartData, thunkAPI) => {
    try {
        return await authService.addAdress(cartData)
    } catch(error){
        return thunkAPI.rejectWithValue(error)
    }
    });
    export const getUserCart = createAsyncThunk("user/cart/get", async(thunkAPI) => {
        try {
            return await authService.getCart()
        } catch(error){
            return thunkAPI.rejectWithValue(error)
        }
        });
        export const getCurrentUser = createAsyncThunk("user/me/get", async(thunkAPI) => {
            try {
                return await authService.getCurrentUser()
            } catch(error){
                return thunkAPI.rejectWithValue(error)
            }
            });
export const getCurrentUserShipping = createAsyncThunk("user/shipping", async(thunkAPI) => {
    try {
        return await authService.getShippingAddress()
    } catch(error){
        return thunkAPI.rejectWithValue(error)
    }
});
export const refreshToken = createAsyncThunk("user/refresh", async(refreshTokenData, thunkAPI) => {
    try {
        return await authService.refresh(refreshTokenData)
    } catch(error){
        return thunkAPI.rejectWithValue(error)
    }
});
export const getOrders = createAsyncThunk("user/orders/get", async(refreshTokenData, thunkAPI) => {
    try {
        return await authService.getUserOrders()
    } catch(error){
        return thunkAPI.rejectWithValue(error)
    }
});
export const updateProfile = createAsyncThunk("user/update", async(data, thunkAPI) => {
    try {
        return await authService.updateUser(data)
    } catch(error){
        return thunkAPI.rejectWithValue(error)
    }
});
export const forgotPwd = createAsyncThunk("user/forgot", async(data, thunkAPI) => {
    try {
        return await authService.forgotPass(data)
    } catch(error){
        return thunkAPI.rejectWithValue(error)
    }
});
export const tokenValid = createAsyncThunk("reset/token", async(token, thunkAPI) => {
    try {
        return await authService.tokenCheck(token)
    } catch(error){
        return thunkAPI.rejectWithValue(error)
    }
});
export const updatePass = createAsyncThunk("update/pass", async(data, thunkAPI) => {
    try {
        return await authService.updatePasswordUser(data)
    } catch(error){
        return thunkAPI.rejectWithValue(error)
    }
});
export const addNewLetterUser = createAsyncThunk("news/add", async(data, thunkAPI) => {
    try {
        return await authService.addNewsLetterSuscriber(data)
    } catch(error){
        return thunkAPI.rejectWithValue(error)
    }
});
export const processPaiementOrder = createAsyncThunk("order/checkout", async(order, thunkAPI) => {
    try {
        return await authService.processPayment(order)
    } catch(error){
        return thunkAPI.rejectWithValue(error)
    }
});
export const processCheckPaiementOrder = createAsyncThunk("order/checkpayement", async(payment, thunkAPI) => {
    try {
        return await authService.checkPayment(payment)
    } catch(error){
        return thunkAPI.rejectWithValue(error)
    }
});
const getCustomerFromLocalStorage = localStorage.getItem("customer") ? JSON.parse(localStorage.getItem("customer")):null;
const getUserFromLocalStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")):null;
const initialState={
    token:getCustomerFromLocalStorage,
    user:getUserFromLocalStorage,
    shipping:false,
    isError:false,
    isOrderProcess:true,
    isSuccess:false,
    isLoading:false,
    isConnected:false,
    message:""
}
export const authSlice = createSlice({
    name:"auth",
    initialState:initialState,
    reducers:{},
    extraReducers:(builder) => {
        builder.addCase(registerUser.pending,(state)=> {
            state.isLoading=true;
        }).addCase(registerUser.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.createUser=action.payload;
            if(state.isSuccess === true){
                toast.info("User creer");
            }
        } ).addCase(registerUser.rejected, (state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
                toast.error("Cet adresse email existe deja");

        }).addCase(loginUser.pending,(state)=> {
            state.isLoading=true;
        }).addCase(loginUser.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.token=action.payload;
            state.isConnected=true;
            if(state.isSuccess === true){
                localStorage.setItem("token",action.payload.token);
                toast.info("Vous êtes connectés");
            }
        } ).addCase(loginUser.rejected, (state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
            if(state.isError === true){
                toast.error("Identifiant et/ou mot de passe invalide");
            }

        }).addCase(refreshToken.pending,(state)=> {
            state.isLoading=true;
        }).addCase(refreshToken.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.token=action.payload;
        } ).addCase(refreshToken.rejected, (state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
            if(state.isError === true){
                toast.error(action.error);
            }

        }).addCase(getUserProductWishlist.pending, (state) => {
            state.isLoading=true;
        }).addCase(getUserProductWishlist.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.wishlist = action.payload;
        }).addCase(getUserProductWishlist.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        }).addCase(addToOrder.pending, (state) => {
            state.isLoading=true;
            state.isOrderProcess=true;
        }).addCase(addToOrder.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.cartProduct = action.payload;
        }).addCase(addToOrder.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.isConnected=false;
            state.isOrderProcess=false;
            
        }).addCase(getUserCart.pending, (state) => {
            state.isLoading=true;
        }).addCase(getUserCart.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.cartProducts = action.payload;
        }).addCase(getUserCart.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        }).addCase(getCurrentUser.pending, (state) => {
            state.isLoading=true;
        }).addCase(getCurrentUser.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.user=action.payload;
            if(state.isSuccess === true){
                localStorage.setItem("user", JSON.stringify(action.payload));
            }
        }).addCase(getCurrentUser.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
            state.user=null;
            localStorage.setItem("user", null);
        }).addCase(getCurrentUserShipping.pending, (state) => {
            state.isLoading=true;
        }).addCase(getCurrentUserShipping.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.shipping=action.payload;
        }).addCase(getCurrentUserShipping.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        }).addCase(getOrders.pending, (state) => {
            state.isLoading=true;
        }).addCase(getOrders.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.orders=action.payload;
        }).addCase(getOrders.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        }).addCase(updateProfile.pending, (state) => {
            state.isLoading=true;
        }).addCase(updateProfile.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.updateUser=action.payload;
            if(state.isSuccess){
                toast.success("Profile mise a jour")
            }
        }).addCase(updateProfile.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
            if(state.isSuccess){
                toast.error("Erreur de mise a jour")
            }
        }).addCase(forgotPwd.pending, (state) => {
            state.isLoading=true;
        }).addCase(forgotPwd.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.updateUser=action.payload;
            if(state.isSuccess){
                toast.success("Une email de reinitialisation vous est envoye")
            }
        }).addCase(forgotPwd.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
                toast.error("Aucun compte n'est associe a l'email")
        }).addCase(tokenValid.pending, (state) => {
            state.isLoading=true;
        }).addCase(tokenValid.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
        }).addCase(tokenValid.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
                toast.error("Aucun compte n'est associe a l'email")
        }).addCase(updatePass.pending, (state) => {
            state.isLoading=true;
        }).addCase(updatePass.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
                toast.success("Mot de passe mise a jour")
        }).addCase(updatePass.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
                toast.error("Aucun compte n'est associe a l'email")
        }).addCase(addNewLetterUser.pending, (state) => {
            state.isLoading=true;
        }).addCase(addNewLetterUser.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
                toast.success("vous êtes abonné")
        }).addCase(addNewLetterUser.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        }).addCase(processPaiementOrder.pending, (state) => {
            state.isLoading=true;
            state.isOrderProcess=true;
        }).addCase(processPaiementOrder.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
        }).addCase(processPaiementOrder.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
            toast.success("Une erreur est survenu durant le processus de paiement")
            state.isOrderProcess=false;
        
        }).addCase(processCheckPaiementOrder.pending, (state) => {
            state.isLoading=true;
            state.isOrderProcess=true;
        }).addCase(processCheckPaiementOrder.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            if(action.payload!=='pending') {
            state.isOrderProcess=false;
            }
        }).addCase(processCheckPaiementOrder.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
            // state.isOrderProcess=true;
        }).addCase(addToAdress.pending, (state) => {
            state.isLoading=true;
            state.isOrderProcess=true;
        }).addCase(addToAdress.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.shipping=action.payload;
        }).addCase(addToAdress.rejected, (state, action) => {
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
            // state.isOrderProcess=true;
        
        })
    }
})
export default authSlice.reducer
import React from "react";
import { Link } from "react-router-dom";
import { BsLinkedin, BsGithub, BsYoutube, BsInstagram } from "react-icons/bs";
import { useFormik } from "formik";
import * as yup from 'yup';
import newsletter from "../images/newsletter.png";
import mvola from "../images/mvola.svg";
import { useDispatch, useSelector } from "react-redux";
import { addNewLetterUser } from "../features/user/userSlice";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
const newsLetterSchema = yup.object({
  email: yup.string().email("Entrez un email valide").required("L'email est obligatoire")
});
const Footer = () => {
  const dispatch = useDispatch()
  const authState = useSelector(state=>state.auth)
  const isLoading = authState.isLoading
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: newsLetterSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(addNewLetterUser(values)).then((data) => {  resetForm() })
    },
  });
  return (
    <>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <footer className="py-4 footer-blue">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="footer-top-data d-flex gap-30 align-items-center">
                <img src={newsletter} alt="newsletter" />
                <h2 className="mb-0 text-white">S'inscrire au newletter</h2>
              </div>
            </div>
           
            <div className="col-md-7">
            <form onSubmit={formik.handleSubmit}>
              <div className="input-group">
              
                <input
                  type="email"
                  className="form-control py-1"
                  placeholder="Votre adresse email"
                  aria-label="Votre adresse email"
                  aria-describedby="basic-addon2"
                  name="email"
                  value={formik.values.email} 
                  onChange={formik.handleChange("email")} 
                  onBlur={formik.handleBlur("email")}/>
                  
                <button type="submit" className="input-group-text p-2" id="basic-addon2">
                  S'inscrire
                </button>
                
              </div>
              <div className="error">
                   {
                     formik.touched.email && formik.errors.email
                   }
                   </div>
              </form>
            </div>
          </div>
        </div>
      </footer>
      <footer className="py-4 footer-clean">
        <div className="container-xxl">
          <div className="row">
            <div className="col-md-4">
              <h4 className="text-gris mb-4">Contact</h4>
              <div>
                <address className="text-gris fs-6">
                  Hno : 277 Near Vill chopal, <br /> Sonipat, Haryana <br />
                  PinCode: 131103
                </address>
                <a
                  href="tel:+91 8264954234"
                  className="mt-3 d-block mb-1 text-gris"
                >
                  +91 8264954234
                </a>
                <a
                  href="mailto:lorem@gmail.com"
                  className="mt-2 d-block mb-0 text-gris"
                >
                  lorem@gmail.com
                </a>
                <div className="social_icons d-flex align-items-center gap-30 mt-4">
                  <a className="text-gris" href="#">
                    <BsLinkedin className="fs-4" />
                  </a>
                  <a className="text-gris" href="#">
                    <BsInstagram className="fs-4" />
                  </a>
                  <a className="text-gris" href="#">
                    <BsGithub className="fs-4" />
                  </a>
                  <a className="text-gris" href="#">
                    <BsYoutube className="fs-4" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <h4 className="text-gris mb-4">Information</h4>
              <div className="footer-link d-flex flex-column">
                <Link to="/privacy-policy" className="text-gris py-2 mb-1">
                  Politique de confidentialite
                </Link>
                <Link to="/refund-policy" className="text-gris py-2 mb-1">
                  Politique de remboursement
                </Link>
                <Link to="/shipping-policy" className="text-gris py-2 mb-1">
                  Livraison
                </Link>
                <Link to="/term-conditions" className="text-gris py-2 mb-1">
                  Mentions légales
                </Link>
               
                <Link to="#" className="text-gris py-2 mb-1">
                <img src={mvola} atl="mvola" width={50}/>
                </Link>
              </div>
            </div>
            <div className="col-md-3">
              <h4 className="text-gris mb-4">Mon compte</h4>
              <div className="footer-link d-flex flex-column">
                <Link className="text-gris py-2 mb-1">Mes commandes</Link>
                <Link className="text-gris py-2 mb-1">Adresse</Link>
              </div>
            </div>
            <div className="col-md-2">
              <h4 className="text-gris mb-4">Liens utile</h4>
              <div className="footer-link d-flex flex-column">
                <Link className="text-gris py-2 mb-1">Laptops</Link>
                <Link className="text-gris py-2 mb-1">Headphones</Link>
                <Link className="text-gris py-2 mb-1">Tablets</Link>
                <Link className="text-gris py-2 mb-1">Watch</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="py-4 footer-clean">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <p className="text-center mb-0 text-gris">
                &copy; {new Date().getFullYear()} Adeen Store
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { brandsService} from "./brandsService";
export const getAllBrands=createAsyncThunk("brand/get", async (thunkAPI) => {
    try{
        return brandsService.getBrands();
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
} );

const brandsState = {
    brands: "",
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:""
}

export const brandsSlice = createSlice({
    name: "brands",
    initialState: brandsState,
    reducers:{},
    extraReducers:(builder) => {
        builder.addCase(getAllBrands.pending,(state)=> {
            state.isLoading=true;
        }).addCase(getAllBrands.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.brands=action.payload;
        } ).addCase(getAllBrands.rejected, (state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
    }
})
export default brandsSlice.reducer
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { productService} from "./productService";
export const getAllProducts=createAsyncThunk("product/get", async (data, thunkAPI) => {
    try{
        return productService.getProducts(data);
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
} );
export const getAProduct=createAsyncThunk("product/getAProduct", async (id, thunkAPI) => {
    try{
        return productService.getSingleProduct(id);
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
} );
export const addToWishlist=createAsyncThunk("product/wishlist", async (prodId, thunkAPI) => {
    try{
        return productService.addToWishlist(prodId);
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
} );
export const getShippingPrice=createAsyncThunk("ship/amount", async (shipData, thunkAPI) => {
    try{
        return productService.getShippingPrice(shipData);
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
} );
export const addRating=createAsyncThunk("product/rating", async (data, thunkAPI) => {
    try{
        return productService.rateProduct(data);
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
} );
export const getAllSpecialProducts=createAsyncThunk("product/special", async (thunkAPI) => {
    try{
        return productService.getSpecialProducts();
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
} );
const productState = {
    product:"",
    singleproduct:"",
    specialproducts:"",
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:""
}

export const productSlice = createSlice({
    name: "product",
    initialState: productState,
    reducers:{},
    extraReducers:(builder) => {
        builder.addCase(getAllProducts.pending,(state)=> {
            state.isLoading=true;
        }).addCase(getAllProducts.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.product=action.payload;
        } ).addCase(getAllProducts.rejected, (state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;

        }).addCase(addToWishlist.pending, (state) => {
            state.isLoading=true;
        }).addCase(addToWishlist.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.addToWishlist=action.payload;
            state.message='Produit ajoute';
        } ).addCase(addToWishlist.rejected, (state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;

        }).addCase(getAProduct.pending, (state) => {
            state.isLoading=true;
        }).addCase(getAProduct.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.singleproduct=action.payload;
            state.message='Produit fecth success';
        } ).addCase(getAProduct.rejected, (state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;

        }).addCase(getShippingPrice.pending, (state) => {
            state.isLoading=true;
        }).addCase(getShippingPrice.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.product=action.payload;
            // state.message='Produit fecth success';
        } ).addCase(getShippingPrice.rejected, (state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;

        }).addCase(addRating.pending, (state) => {
            state.isLoading=true;
        }).addCase(addRating.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.product=action.payload;
            toast.info("Commentaire ajoute");
        } ).addCase(addRating.rejected, (state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        }).addCase(getAllSpecialProducts.pending, (state) => {
            state.isLoading=true;
        }).addCase(getAllSpecialProducts.fulfilled, (state, action) => {
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.specialproducts=action.payload;
        } ).addCase(getAllSpecialProducts.rejected, (state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;

        })
    }
})
export default productSlice.reducer
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../components/BreadCrumb";
import Container from "../components/Container";
import { getOrders } from "../features/user/userSlice";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
const Orders = () => {
    const dispatch = useDispatch()
    const orderState = useSelector((state) => state.auth.orders)
    const isLoading = useSelector((state) => state.auth.isLoading)
    useEffect(()=> {
        dispatch(getOrders())
    },[])
    return (
        <>
        <BreadCrumb title="Mes commandes"></BreadCrumb>
        <Container class1="cart-wrapper home-wrapper-2 py-5">
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-3">
                            <h5>Id</h5>
                        </div>
                        <div className="col-3">
                            <h5>Total</h5>
                        </div>
                        <div className="col-3">
                            <h5>Total apres remise</h5>
                        </div>
                        <div className="col-3">
                            <h5>Status</h5>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    {
                        orderState && orderState?.map((item, index) => {
                            return (
<div className="row bg-warning my-3" key={index}>
                        <div className="col-3">
                            <p>{item?.id}</p>
                        </div>
                        <div className="col-3">
                            <p>{item?.total_wrapping_tax_excl}</p>
                        </div>
                        <div className="col-3">
                            <p>{item?.total_wrapping_tax_incl}</p>
                        </div>
                        <div className="col-3">
                            <p>{item?.status}</p>
                        </div>
                        <div className="col-12">
                        <div className="row bg-secondary py-3">
                        <div className="col-3">
                            <h6>Produit</h6>
                        </div>
                        <div className="col-3">
                            <h6>Quantite</h6>
                        </div>
                        <div className="col-3">
                            <h6>Prix</h6>
                        </div>
                        <div className="col-3">
                            <h6>Total</h6>
                        </div>
                        {
                            item.order_item?.map((i,index) => {
                                return (
<div className="col-12" key={index}>
                        <div className="row bg-secondary py-3">
                        <div className="col-3">
                            <p>{i.product}</p>
                        </div>
                        <div className="col-3">
                            <p>{i.qte}</p>
                        </div>
                        <div className="col-3">
                            <p>{i.unit_price}</p>
                        </div>
                        <div className="col-3">
                            <p>{i.total_price}</p>
                        </div>
                    </div>
                        </div>
                                )

                            })
                        }
                        
                    </div>
                    
                        </div>
                    </div>
                            )

                        })
                    }
                
                </div>
            </div>
        </Container>
        </>
    )
}

export default Orders
import React, { useEffect } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import watch from "../images/watch.jpg";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import Container from "../components/Container";
import { useDispatch } from "react-redux";
import { login_url } from "../utils/axiosConfig";

function Cart({ cart, updateCart}) {
  const total = cart.reduce((acc , productType) => acc + productType.qte * productType.price,0)
  const dispatch = useDispatch()

  function removeToCart(productId) {
		const currentProductSaved = cart.find((product) => product.productId === productId)
		if (currentProductSaved) {
			const cartFilteredCurrentProduct = cart.filter(
				(product) => product.productId !== productId
			)
      if(cartFilteredCurrentProduct.length>0) {
        console.log(cartFilteredCurrentProduct);
        console.log(...cartFilteredCurrentProduct);
        // updateCart([])
        // updateCart([...cart, ...cartFilteredCurrentProduct])
        localStorage.setItem('cart',JSON.stringify(cartFilteredCurrentProduct))
        updateCart(JSON.parse(localStorage.getItem('cart')))
        
      } else {
        updateCart([])
      }
      
			// localStorage.setItem('cart',JSON.stringify(cartFilteredCurrentProduct))
		}
	}
  function updateQuantityFroCart(productId, productTitle, price, quantity, image){
    const currentProductSaved = cart.find((product) => product.productId === productId)
		if (currentProductSaved) {
			const cartFilteredCurrentProduct = cart.filter(
				(product) => product.productId !== productId
			)
			updateCart([
				...cartFilteredCurrentProduct,
				{ productId, productTitle, price, qte: quantity, image}
			])
		}
  }
  return (
    <>
      <Meta title={"Panier"} />
      <BreadCrumb title="Panier" />
      <Container class1="cart-wrapper home-wrapper-2 py-5">
      {cart.length > 0 ? (
        <div className="row">
          <div className="col-12">
            <div className="cart-header py-3 d-flex justify-content-between align-items-center">
              <h4 className="cart-col-1">Produit</h4>
              <h4 className="cart-col-2">Prix</h4>
              <h4 className="cart-col-3">Quantite</h4>
              <h4 className="cart-col-4">Total</h4>
            </div>
            {cart.map(({productId, productTitle, price, qte, image}, index)=> (
              <div key={`${productId}-${index}`} className="cart-data py-3 mb-2 d-flex justify-content-between align-items-center">
              <div className="cart-col-1 gap-15 d-flex align-items-center">
                <div className="w-25">
                  <img  src={`${login_url}${image}`} className="img-fluid" alt="product image" />
                </div>
                <div className="w-75">
                  <p>{productTitle}</p>
                  {/* <p>Size: hgf</p>
                  <p>Color: gfd</p> */}
                </div>
              </div>
              <div className="cart-col-2">
                <h5 className="price">{price} Ar</h5>
              </div>
              <div className="cart-col-3 d-flex align-items-center gap-15">
                <div>
                  <input
                    className="form-control"
                    type="number"
                    name=""
                    min={1}
                    max={10}
                    id=""
                    value={qte}
                    onChange={(e)=>{updateQuantityFroCart(productId,productTitle,price,e.target.value,image)}}
                  />
                </div>
                <div>
                  <AiFillDelete onClick={() => removeToCart(productId)} className="text-danger " />
                </div>
              </div>
              <div className="cart-col-4">
                <h5 className="price">{price*qte} Ar</h5>
              </div>
            </div>
            ))}
            
          </div>
          <div className="col-12 py-2 mt-4">
            <div className="d-flex justify-content-between align-items-baseline">
              <Link to="/boutique" className="button">
                Continuer vos achats
              </Link>
              <div className="d-flex flex-column align-items-end">
                <h4>Total: {total} Ar</h4>
                <p>La livraison sera calculer dans votre commande</p>
                <Link to="/checkout" className="button">
                  Commander
                </Link>
              </div>
            </div>
          </div>
        </div>
        ) : (
          <div className="row">
            <div className="col-12">Votre panier est vide</div>
          </div>
			)}
      </Container>
    </>
  );
};

export default Cart;

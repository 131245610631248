import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import { login_url } from "../utils/axiosConfig";
const SpecialProduct = (props) => {
  const {id, title, brand, rating, price, sold, quantity, days, hours, mins, secs, remainig, picture} = props;
  const [day, setDay] = useState(days);
  const [hour, setHour] = useState(hours);
  const [min, setMinute] = useState(mins);
  const [sec, setSecond] = useState(secs);
  useEffect(() => {
    let sampleInterval = setInterval(() => {
      if (sec > 0) {
        setSecond(sec - 1);
      }
      if (sec === 0) {
        if (min === 0) {
          if(hour===0) {
          clearInterval(sampleInterval);
        } else {
          setHour(hour - 1);
          setMinute(min - 1);
          setSecond(59);
        }
      } else {
        if(hour===0) {
          setMinute(min - 1);
          setSecond(59);
        } else {
          setHour(hour - 1);
          setMinute(min - 1);
          setSecond(59);
        }
        }
      }
      if (min === 0) {
          if(hour===0) {
          clearInterval(sampleInterval);
        } else {
          setHour(hour - 1);
          setMinute(59);
          setSecond(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(sampleInterval);
    };
  });
  return (
    <>
      <div className="col-md-6 mb-3">
        <div className="special-product-card">
          <div className="d-flex justify-content-between">
            <div>
            <img src={`${login_url}/images/products/${picture}`} className="img-fluid" alt="product image" width={160} />
            </div>
            <div className="special-product-content">
              <h5 className="brand">{brand}</h5>
              <h6 className="title">
              {title}
              </h6>
              <ReactStars
                count={5}
                size={24}
                value={rating}
                edit={false}
                activeColor="#ffd700"
              />
              <p className="price">
                <span className="red-p">{price}</span> &nbsp; 
                <strike>{sold}</strike>
              </p>
              <div className="discount-till d-flex align-items-center gap-10">
                <p className="mb-0">
                  {day >2 ? `${day} Jours`:`${day} Jour`} 
                </p>
                <div className="d-flex gap-10 align-items-center">
                {(day) ? "" : (
        <p>
          {/* {"ssss "} */}
          <span className="badge rounded-circle p-3 bg-danger">{hour}</span>:<span className="badge rounded-circle p-3 bg-danger">{min < 10 ? `0${min}` : min}</span>:<span className="badge rounded-circle p-3 bg-danger">{sec < 10 ? `0${sec}` : sec}</span>
        </p>
      )}
                </div>
              </div>
              <div className="prod-count my-3">
                <p>Produits: {quantity}</p>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: remainig + "%" }}
                    aria-valuenow={remainig}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
              <Link className="button" to={'/product/'+id}>Voir</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialProduct;

import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import OurStore from "./pages/OurStore";
import Blog from "./pages/Blog";
import CompareProduct from "./pages/CompareProduct";
import Wishlist from "./pages/Wishlist";
import Login from "./pages/Login";
import Forgotpassword from "./pages/Forgotpassword";
import Signup from "./pages/Signup";
import Resetpassword from "./pages/Resetpassword";
import SingleBlog from "./pages/SingleBlog";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPloicy from "./pages/RefundPloicy";
import ShippingPolicy from "./pages/ShippingPolicy";
import TermAndContions from "./pages/TermAndContions";
import SingleProduct from "./pages/SingleProduct";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import { useEffect, useState } from "react";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import { OpenRoutes } from "./routing/OpenRoutes";
import { useDispatch, useSelector } from "react-redux";

import Orders from "./pages/Orders";
import Profile from "./pages/Profile";
import NotFound from "./pages/NotFound";
import ThankYou from "./pages/ThanYou";
import { getAllCategoriesNav } from "./features/categories/categoriesSlice";
function App() {
  const savedCart = localStorage.getItem('cart')
  const [cart, updateCart] = useState(savedCart ? JSON.parse(savedCart) : [])
  const authState = useSelector(state =>state.auth)
  const categoriesStateNav = useSelector((state) => state?.categories?.categoriesNav);
  const dispatch = useDispatch()
  useEffect(()=> {
    dispatch(getAllCategoriesNav());
  },[]);
  useEffect(() => {
		
	}, [authState])
	useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart)) 
	}, [cart])
  useEffect(() => {
    window.addEventListener('storage', () => {
        updateCart(JSON.parse(localStorage.getItem('cart')))
});
	}, [])
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout cart={cart}  updateCart={updateCart} categoriesStateNav={categoriesStateNav} />}>
            <Route index element={<Home categoriesStateNav={categoriesStateNav} cart={cart} updateCart={updateCart}/>} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="boutique/*" element={<OurStore categoriesStateNav={categoriesStateNav} cart={cart} updateCart={updateCart} />} />
            <Route path="product/:id" element={<SingleProduct cart={cart} updateCart={updateCart}/>} />
            <Route path="blogs" element={<Blog />} />
            <Route path="blog/:id" element={<SingleBlog />} />
            <Route path="cart" element={<Cart cart={cart} updateCart={updateCart}/>} />
            <Route path="my-orders" element={<PrivateRoutes><Orders /></PrivateRoutes>} />
            <Route path="my-profile" element={<PrivateRoutes><Profile /></PrivateRoutes>} />
            <Route path="checkout" element={<PrivateRoutes><Checkout cart={cart} updateCart={updateCart} /></PrivateRoutes>} />
            <Route path="compare-product" element={<CompareProduct />} />
            <Route path="wishlist" element={<PrivateRoutes><Wishlist /></PrivateRoutes>} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<Forgotpassword />} />
            <Route path="signup" element={<OpenRoutes><Signup /></OpenRoutes>} />
            <Route path="reset-password/:token" element={<Resetpassword />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="refund-policy" element={<RefundPloicy />} />
            <Route path="shipping-policy" element={<ShippingPolicy />} />
            <Route path="term-conditions" element={<TermAndContions />} />
            <Route path="term-conditions" element={<TermAndContions />} />
            <Route path="thank-you" element={<ThankYou />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

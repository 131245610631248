import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategoriesNav } from "../features/categories/categoriesSlice";
import menu from "../images/menu.svg";
function NavbarMenu({categoriesStateNav}) {
  const authState = useSelector(state =>state?.auth)
  const handleLogout = ()=>{
    localStorage.clear()
    window.location.reload()
  }
    return (
      <Navbar expand="lg" bg="transparent" className="" variant="light">
        <Container>
          <Navbar.Brand href="/"> <img src={menu} alt="" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"  variant="light" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="CATEGORIES" className="dropdown text-white">
                {
                     categoriesStateNav && categoriesStateNav.map((item, index) => {
                        return (
                         item.child ? 
                        <NavDropdown key={index} title={item.name} className="dropend">
                            { item.child.map((item2, i) => {
                                    return <NavDropdown.Item key={i} className="dropdown-item" href={`/boutique/${item2.slug}`}>
                                   {item2.name}
                                    </NavDropdown.Item>
                            })}
                            </NavDropdown>
                            :
                    <NavDropdown.Item key={index} className="dropdown-item" href={`/boutique/${item.slug}`}>
                      {item.name}
                    </NavDropdown.Item>
                        
                        )
                     })
                }
                
                {/* <NavDropdown.Item className="dropdown-item" href="#action/3.2">
                  Main Level 2
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" href="#action/3.3">
                  Main Level 3
                </NavDropdown.Item> */}
                {/* <NavDropdown title="Main Level 4" className="dropend">
                  <NavDropdown.Item className="dropdown-item" href="#action/3.1">
                    Sub Level 1
                  </NavDropdown.Item>
                  <NavDropdown title="Sub Level 2" className="dropend">
                    <NavDropdown.Item
                      className="dropdown-item"
                      href="#action/3.1"
                    >
                      Final Level 1
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="dropdown-item"
                      href="#action/3.2"
                    >
                      Final Level 2
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="dropdown-item"
                      href="#action/3.3"
                    >
                      Final Level 3
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown.Item className="dropdown-item" href="#action/3.3">
                    Sub Level 3
                  </NavDropdown.Item>
                  <NavDropdown title="Sub Level 4" className="dropend">
                    <NavDropdown.Item
                      className="dropdown-item"
                      href="#action/3.1"
                    >
                      Final Level 1
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="dropdown-item"
                      href="#action/3.2"
                    >
                      Final Level 2
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="dropdown-item"
                      href="#action/3.3"
                    >
                      Final Level 3
                    </NavDropdown.Item>
                  </NavDropdown>
                </NavDropdown> */}
              </NavDropdown>
              <Nav.Link href="/">ACCEUIL</Nav.Link>
            <Nav.Link href="/boutique">BOUTIQUE</Nav.Link>
            {
                        authState.user!==null ?
            <Nav.Link href="/my-orders">MES COMMANDES</Nav.Link>:""
}
<Nav.Link href="/blogs">BLOGS</Nav.Link>
<Nav.Link href="/contact">CONTACT</Nav.Link>
{
                        authState.user!==null ?
                        <button className="border border-0 bg-transparent text-black text-uppercase" onClick={handleLogout} type="button">Deconnexion</button>:""
                    }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
  
  export default NavbarMenu;
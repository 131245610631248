import axios from "axios";
import { base_url, config, login_url,config_init } from "../../utils/axiosConfig";

const register=async(userData)=> {
    const response = await axios.post(`${base_url}register`,userData);
    if(response.data) {
        return response.data;
    }
}
const login=async(userData)=> {
    const response = await axios.post(`${login_url}authentication_token`,userData);
    if(response.data) {
        if(response.data) {
        localStorage.setItem("customer", JSON.stringify(response.data));
    }
        return response.data;
    }
}
const refresh=async(refreshToken)=> {
    console.log(refreshToken);
    const response = await axios.post(`${login_url}token/refresh`,refreshToken);
    if(response.data) {
        if(response.data) {
        localStorage.setItem("customer", JSON.stringify(response.data));
    }
        return response.data;
    }
}
const getUserWishlist  = async() => {
    const response = await axios.get(`${base_url}user/wishlist`, config);
    if(response.data) {
        return response.data;
    }
}
const getCurrentUser  = async() => {
    const token = localStorage.getItem("customer") ? JSON.parse(localStorage.getItem("customer")):null;
    const config = {
        headers: {}
    }
    const config_header = {
        headers: {
            Authorization: `Bearer ${
                token.token !== null ? token.token : ""
            }`,
            Accept: "application/json"
           
        }
    }
    const response = await axios.get(`${base_url}me`, config_header);
    if(response.data) {
        return response.data;
    }
}
const addToOrder  = async(cartData) => {
    const token = localStorage.getItem("customer") ? JSON.parse(localStorage.getItem("customer")):null;
    const config = {
        headers: {}
    }
    const config_header = {
        headers: {
            Authorization: `Bearer ${
                token.token !== null ? token.token : ""
            }`,
            Accept: "application/json"
           
        }
    }
    const response = await axios.post(`${base_url}order/add`, cartData, config_header);
    if(response.data) {
        return response.data;
    }
}
const addAdress  = async(cartData) => {
    const token = localStorage.getItem("customer") ? JSON.parse(localStorage.getItem("customer")):null;
    const config = {
        headers: {}
    }
    const config_header = {
        headers: {
            Authorization: `Bearer ${
                token.token !== null ? token.token : ""
            }`,
            Accept: "application/json"
           
        }
    }
    const response = await axios.post(`${base_url}ship_add`, cartData, config_header);
    if(response.data) {
        return response.data;
    }
}
const getUserOrders = async()=> {
    const response = await axios.get(`${base_url}user/getmyorders`, config);
    if(response.data) {
        return response.data;
    }
}

const getCart  = async() => {
    const response = await axios.get(`${base_url}user/cart`, config);
    if(response.data) {
        return response.data;
    }
}
const getShippingAddress  = async() => {
    const response = await axios.get(`${base_url}user/get_ship`, config);
    if(response.data) {
        return response.data;
    }
}
const updateUser  = async(userData) => {
    const response = await axios.put(`${base_url}user/update`, userData, config);
    if(response.data) {
        return response.data;
    }
}
const forgotPass = async(email) => {
    const response = await axios.put(`${base_url}user/forgot-pass`, email);
    if(response.data) {
        return response.data;
    }
}
const tokenCheck = async(token) => {
    const response = await axios.get(`${base_url}user/reset-pass/${token}`);
    if(response.data) {
        return response.data;
    }
}
const updatePasswordUser = async(data) => {
    const response = await axios.post(`${base_url}user/update-pass`,data);
    if(response.data) {
        return response.data;
    }
}
const addNewsLetterSuscriber = async(data) => {
    const response = await axios.post(`${base_url}new_letters`,data);
    if(response.data) {
        return response.data;
    }
}
const processPayment = async(order) => {
    const token = localStorage.getItem("customer") ? JSON.parse(localStorage.getItem("customer")):null;
    const config = {
        headers: {}
    }
    const config_header = {
        headers: {
            Authorization: `Bearer ${
                token.token !== null ? token.token : ""
            }`,
            Accept: "application/json"
           
        }
    }
    const response = await axios.post(`${base_url}order/${order}/checkout`, order, config_header);
    if(response.data) {
        return response.data;
    }
}
const checkPayment = async(payment) => {
    const token = localStorage.getItem("customer") ? JSON.parse(localStorage.getItem("customer")):null;
    const config = {
        headers: {}
    }
    const config_header = {
        headers: {
            Authorization: `Bearer ${
                token.token !== null ? token.token : ""
            }`,
            Accept: "application/json"
           
        }
    }
    const response = await axios.get(`${base_url}order/${payment}/checkpayment`,config_header);
    if(response.data) {
        return response.data;
    }
}
export const authService={
    register,
    login,
    getUserWishlist,
    addToOrder,
    getCart,
    getCurrentUser,
    getShippingAddress,
    refresh,
    getUserOrders,
    updateUser,
    forgotPass,
    tokenCheck,
    updatePasswordUser,
    addNewsLetterSuscriber,
    processPayment,
    checkPayment,
    addAdress,
}
import React from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import wishlist from "../images/wishlist.svg";
import user from "../images/user.svg";
import cartimg from "../images/cart.svg";
import logo from "../images/logo.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useState } from "react";
import { getAProduct } from "../features/products/productSlice";
import NavbarMenu from "./Navbar";
import { Badge, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
const Header = ({cart, updateCart, categoriesStateNav}) => {
  const total = cart.reduce((acc , productType) => acc + productType.qte * productType.price,0)
  const authState = useSelector(state =>state?.auth)
  const [paginate, setPaginate] = useState(true);
  const productStateOpt = useSelector(state=>state?.product?.product)
  const [productOpt, setProductOpt] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
 
  useEffect(() => {
		document.title = `LMJ: ${total} Ar d'achats`
	}, [total])
  useEffect(()=> {
    let data = [];
    let category=[];
    for(let index =0; index < productStateOpt?.length; index++) {
      const element = productStateOpt[index]
      data.push({id:index, prod:element?.slug, name:element?.title})
      category.push(element?.category?.name)
    }
    setProductOpt(data);
  },[productStateOpt])
  return (
    <>
      <header className="header-top-strip py-3">
        <div className="container-xxl">
          <div className="row">
            <div className="col-md-6">
              <p className="text-black mb-0">
                Livraison gratuite pour une achat a partir  de 50 000 ar
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-end text-black mb-0">
                Tel:
                <a className="text-black" href="tel:+261341430229"> +261 34 14 302 29</a>
              </p>
            </div>
          </div>
        </div>
      </header>
      <header className="header-upper py-3">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-md-3">
              <h2 className="titre-logo">
                <Link to="/" className="text-black"><img src={logo} alt="adeen store" width={150}/></Link>
              </h2>
            </div>
            <div className="col-md-5">
              <div className="input-group">
              <Typeahead
        id="pagination-example"
        onPaginate={() => console.log('Results paginated')}
        onChange={(selected) => {
          if(selected[0]?.prod) {
          navigate(`product/${selected[0]?.prod}`)
          dispatch(getAProduct(selected[0]?.prod))
          }
        }}
        options={productOpt}
        paginate={paginate}
        labelKey={"name"}
        minLength={3}
        placeholder="Recherche produit ..."
      />
                <span className="input-group-text p-3" id="basic-addon2">
                  <BsSearch className="fs-6" />
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="header-upper-links d-flex align-items-center justify-content-between">
                {/* <div>
                  <Link
                    to="/compare-product"
                    className="d-flex align-items-center gap-10 text-white"
                  >
                    <img src={compare} alt="compare" />
                    <p className="mb-0">
                      Compare <br /> Products
                    </p>
                  </Link>
                </div> */}
                <div>
                  <Link
                    to="/wishlist"
                    className="d-flex align-items-center gap-10 text-black"
                  >
                    <img src={wishlist} alt="wishlist" />
                    <p className="mb-0">
                      Favoris
                    </p>
                  </Link>
                </div>
                <div>
                  <Link
                    to={authState?.user===null ? "/login":"/my-profile"} 
                    className="d-flex align-items-center gap-10 text-black"
                  >
                    {
                      authState.user===null ?
                      <p className="mb-0">
                      Se connecter
                    </p>
                      :<p className="mb-0">
                      Bievenue {authState.user.name}
                    </p>
                    }
                    <img src={user} alt="user" />
                    
                  </Link>
                </div>
                <div>
                  <Link
                    to="/cart"
                    className="d-flex align-items-center gap-10 text-black"
                  >
                    <img src={cartimg} alt="cart" />
                    <div className="d-flex flex-column gap-10">
                      {/* <span className="badge bg-white text-dark">{cart?.length ? cart?.length:0 }</span> */}
                      {cart?.length ?
                      <Badge pill  bg="danger">{cart?.length ? cart?.length:0 }</Badge>:''}
                      {/* <p className="mb-0">{total ? total:0} Ar</p> */}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <header className="header-bottom py-3">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <div className="menu-bottom d-flex align-items-center gap-30">
                <div>
                  {/* <div className="dropdown"> */}
                    <NavbarMenu categoriesStateNav={categoriesStateNav}/>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

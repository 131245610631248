export const base_url = "https://api.lovasoaimmobilier-madagascar.com/api/";
export const login_url = "https://api.lovasoaimmobilier-madagascar.com/";
const getTokenFromLocalStorage = localStorage.getItem("customer") ? JSON.parse(localStorage.getItem("customer")):null;
const getSessionFromLocalStorage = localStorage.getItem("sessioncart") ? JSON.parse(localStorage.getItem("sessioncart")):null;
export const config = {
    headers: {
        Authorization: `Bearer ${
            getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
        }`,
        Accept: "application/json",
        "Cache-Control": "no-cache",
    }
}
export const config_init = {
    headers: {
        "Cache-Control": "no-cache",
    }
}
import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const Layout = ({cart, updateCart, categoriesStateNav}) => {
  return (
    <>
      <Header cart={cart} updateCart={updateCart} categoriesStateNav={categoriesStateNav}/>
      <Outlet />
      <Footer categoriesStateNav={categoriesStateNav}/>
      <ToastContainer
      position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </>
  );
};

export default Layout;

import axios from "axios";
import { base_url} from "../../utils/axiosConfig";

const getBrands=async()=> {
    const response = await axios.get(`${base_url}brands/`);
    if(response.data) {
        return response.data;
    }
}

export const brandsService={
    getBrands,
}
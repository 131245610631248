import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import Container from "../components/Container";
import CustomInput from "../components/CustomInput";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { registerUser } from "../features/user/userSlice";
import { useNavigate } from "react-router-dom";

const phoneRegex = /^\(?([0-9]{1})\)?[-. ]?(34)[-. ]?([0-9]{7})$/;
const signupSchema = yup.object({
  username: yup.string().required("Le nom est obligatoire"),
  email: yup.string().email("Entrez un email valide").required("L'email est obligatoire"),
  phone: yup.string().required("Le telephone est obligatoire").matches(phoneRegex,'Le numero doit etre de forme 0343500003'),
  password: yup.string().required("Mot de passe obligatoire").min(8, 'Mot de passe trop court'),
  confpassword: yup.string().required("La confirmation est obligatoire").oneOf([yup.ref("password"), null], "Mot de passe non identique")
});
const Signup = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      phone: '',
      password: '',
      confpassword: '',
    },
    validationSchema: signupSchema,
    onSubmit: values => {
      dispatch(registerUser(values)).then((data) => { if(!data.error) {navigate('/login')}});
    },
  });
  return (
    <>
      <Meta title={"Inscription"} />
      <BreadCrumb title="Inscription" />  
      <Container class1="login-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-md-12">
            <div className="auth-card">
              <h3 className="text-center mb-3">Inscription</h3>
              <form action="" onSubmit={formik.handleSubmit} className="d-flex flex-column gap-15">
                <CustomInput type="text" name="username" placeholder="Nom" 
                value={formik.values.username} 
                onChange={formik.handleChange("username")} 
                onBlur={formik.handleBlur("username")}/>
                <div className="error">
                  {
                    formik.touched.username && formik.errors.username
                  }
                </div>
                <CustomInput type="email" name="email" placeholder="Email" value={formik.values.email} 
                 onChange={formik.handleChange("email")} 
                 onBlur={formik.handleBlur("email")}/>
                 <div className="error">
                   {
                     formik.touched.email && formik.errors.email
                   }
                 </div>
                <CustomInput
                  type="tel"
                  name="phone"
                  placeholder="Telephone"
                  value={formik.values.phone} 
                  onChange={formik.handleChange("phone")} 
                  onBlur={formik.handleBlur("phone")}/>
                  <div className="error">
                    {
                      formik.touched.phone && formik.errors.phone
                    }
                  </div>
                <CustomInput
                  type="password"
                  name="password"
                  placeholder="Mot de passe"
                  value={formik.values.password} 
                  onChange={formik.handleChange("password")} 
                  onBlur={formik.handleBlur("password")}/>
                  <div className="error">
                    {
                      formik.touched.password && formik.errors.password
                    }
                  </div>
                  <CustomInput
                  type="password"
                  name="confpassword"
                  placeholder="Confirmation de Mot de passe"
                  value={formik.values.confpassword} 
                  onChange={formik.handleChange("confpassword")} 
                  onBlur={formik.handleBlur("confpassword")}/>
                  <div className="error">
                   {
                     formik.touched.confpassword && formik.errors.confpassword
                   }
                 </div>
                <div>
                  <div className="mt-3 d-flex justify-content-center gap-15 align-items-center">
                    <button className="button border-0">Confirmer</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Signup;

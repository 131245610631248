import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import ProductCard from "../components/ProductCard";
import ReactImageZoom from "react-image-zoom";
import placeholderImg from "../images/placeholder.svg";
import Color from "../components/Color";
import { TbGitCompare } from "react-icons/tb";
import { AiOutlineHeart } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import watch from "../images/watch.jpg";
import Container from "../components/Container";
import { useDispatch, useSelector } from "react-redux";
import { addRating, getAProduct } from "../features/products/productSlice";
import { toast } from "react-toastify";
import wish from "../images/wish.svg";
import addcart from "../images/add-cart.svg";
import view from "../images/view.svg";
import { getAllProducts, addToWishlist } from "../features/products/productSlice";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Placeholder } from "react-bootstrap";
import { login_url } from "../utils/axiosConfig";

const SingleProduct = ({ cart, updateCart }) => {

  function addToCart(productId, productTitle, quantity, color, price, image) {
		const currentProductSaved = cart.find((product) => product.productId === productId)
		if (currentProductSaved) {
			const cartFilteredCurrentProduct = cart.filter(
				(product) => product.productId !== productId
			)
			updateCart([
				...cartFilteredCurrentProduct,
				{ productId, productTitle, price, qte: currentProductSaved.qte + quantity, image}
			])
		} else {
			updateCart([...cart, { productId, productTitle,  price, qte: quantity, image }])
		}
    toast.success(productTitle+' ajoute dans le panier');
	}
  
  const location = useLocation();
  const [alreadyAdded, setAlreadyAdded] = useState(false)
  const getProductId= location.pathname.split('/')[2];
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const productState = useSelector(state => state?.product?.singleproduct);
  const productsState = useSelector(state => state?.product?.product);

  useEffect(() => {
    dispatch(getAProduct(getProductId));
  },[]);
  useEffect(() => {
    for (let index=0; index < cart.length; index++) {
      if(getProductId==cart[index]?.productId) {
        setAlreadyAdded(true);
        console.log(alreadyAdded)
      }
    
    }
  },[]);
  const uploadCart = () => {
    // if(color===null) {
    //   toast.error('Veuillez selectionner une couleur');
    //   return false;
    // } 
    // dispatch(addProdToCart({productId:productState?.id,quantity,color,price:productState?.price}));
    addToCart(productState?.id, productState?.title, quantity,color,productState?.price, productState?.imageUrl)
  }
  const [color, setColor] = useState(null);
  const [quantity, setQuantity] = useState(1);
  // console.log(quantity);
  const props = {
    width: 594,
    height: 600,
    zoomWidth: 600,

    img: login_url+productState?.imageUrl,
  };

  const [orderedProduct, setorderedProduct] = useState(true);
  const copyToClipboard = (text) => {
    console.log("text", text);
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };
  const closeModal = () => {};
  const [popularProduct, setPopularProduct] = useState()
  useEffect(()=>{
    let data=[]
    for (let index=0; index < productsState.length; index++) {
      const element = productsState[index]
      if(element.tags=='popular' && element.slug!==getProductId) {
        data.push(element)
      }

    }
    setPopularProduct(data)
  },[productsState, productState])
  useEffect(()=> {
    getProducts();
  },[]);
  const addToWish = (id) => {
    dispatch(addToWishlist(id))
     }
  const getProducts = () => {
    dispatch(getAllProducts({}));
  };
  const [star,setStar] = useState(null)
  const [comment, setComment] = useState(null)
  const addRatingToProduct = () => {
    if(star===null) {
      toast.error("Veuillez ajouter un etoile")
      return false
    } else if(comment===null) {
      toast.error("Veuillez saisir un commentaire")
      return false
    } else {
      dispatch(addRating({star:star, comment:comment, prod:getProductId})).then((data) => { if (data.error) {navigate("/login")} else { dispatch(getAProduct(getProductId))}})
    }
    return false

  }
  const isLoading = useSelector((state) => state.product.isLoading)
  return (
    <>
      <Meta title={productState?.title} />
      <BreadCrumb title={productState?.title} />
      <Container class1="main-product-wrapper py-5 home-wrapper-2">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <div className="row">
          <div className="col-md-6">
          {
              isLoading ? 
              <div className="main-product-image"> <img src={placeholderImg}/>
              </div>:
              <>
            <div className="main-product-image">
              <div>
                <ReactImageZoom {...props} />
              </div>
            </div>
            <div className="other-product-images d-flex flex-wrap gap-15">
              <div>
                <img
                  src={`${login_url}${productState?.imageUrl}`}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div>
                <img
                  src={`${login_url}${productState?.imageUrl}`}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div>
                <img
                  src={`${login_url}${productState?.imageUrl}`}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div>
                <img
                  src={`${login_url}${productState?.imageUrl}`}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            </>
            }
          </div>
          <div className="col-md-6">
            {
              isLoading ? 
              <div className="main-product-details"> <Placeholder animation="glow">
              <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
              <Placeholder xs={6} /> <Placeholder xs={8} />
            </Placeholder>
              </div>:
            
            <div className="main-product-details">
              <div className="border-bottom">
                <h3 className="title">
                  {productState?.title}
                </h3>
              </div>
              <div className="border-bottom py-3">
                <p className="price">Ar {productState?.price}</p>
                <div className="d-flex align-items-center gap-10">
                <ReactStars
                count={5}
                size={24}
                value={productState?.totalrating}
                edit={false}
                activeColor="#ffd700"
              />
                  {/* <p className="mb-0 t-review">( 2 Reviews )</p> */}
                </div>
                {/* <a className="review-btn" href="#review">
                  Write a Review
                </a> */}
              </div>
              <div className=" py-3">
                {/* <div className="d-flex gap-10 align-items-center my-2">
                  <h3 className="product-heading">Type :</h3>
                  <p className="product-data">Watch</p>
                </div> */}
                <div className="d-flex gap-10 align-items-center my-2">
                  <h3 className="product-heading">Marque :</h3>
                  <p className="product-data">{productState?.brand?.name}</p>
                </div>
                <div className="d-flex gap-10 align-items-center my-2">
                  <h3 className="product-heading">Categorie :</h3>
                  <p className="product-data">{productState?.category?.name}</p>
                </div>
                <div className="d-flex gap-10 align-items-center my-2">
                  <h3 className="product-heading">Tags :</h3>
                  <p className="product-data">{productState?.tags}</p>
                </div>
                <div className="d-flex gap-10 align-items-center my-2">
                  <h3 className="product-heading">Etat :</h3>
                  <p className="product-data">{productState?.etat}</p>
                </div>
                {/* <div className="d-flex gap-10 flex-column mt-2 mb-3">
                  <h3 className="product-heading">Size :</h3>
                  <div className="d-flex flex-wrap gap-15">
                    <span className="badge border border-1 bg-white text-dark border-secondary">
                      S
                    </span>
                    <span className="badge border border-1 bg-white text-dark border-secondary">
                      M
                    </span>
                    <span className="badge border border-1 bg-white text-dark border-secondary">
                      XL
                    </span>
                    <span className="badge border border-1 bg-white text-dark border-secondary">
                      XXL
                    </span>
                  </div>
                </div> */}
                {/* {
                  alreadyAdded === false && <>
                      <div className="d-flex gap-10 flex-column mt-2 mb-3">
                  <h3 className="product-heading">Color :</h3>
                  <Color setColor={setColor} data={productState?.variations} />
                </div>
                  </>
                } */}
            
                <div className="d-flex align-items-center gap-15 flex-row mt-2 mb-3">
                  {
                    alreadyAdded === false && <>
                    <h3 className="product-heading">Quantity :</h3>
                  <div className="">
                    <input
                      type="number"
                      name=""
                      min={1}
                      max={10}
                      className="form-control"
                      style={{ width: "70px" }}
                      id=""
                      onChange={(e) => setQuantity(e.target.value)}
                      value={quantity}
                    />
                  </div>
                    </>
                  }
                  
                  <div className={ alreadyAdded ? 'ms-0':'ms-5' + 'd-flex align-items-center gap-30 ms-5'}>
                    <button
                      className="button border-0"
                      // data-bs-toggle="modal"
                      // data-bs-target="#staticBackdrop"
                      type="button" onClick={() => {alreadyAdded ? navigate('/cart'): uploadCart(productState?.id)}}
                    >
                     {alreadyAdded ? 'Voir le panier':'Ajouter dans Panier'}
                    </button>
                    {/* <button className="button signup">Buy It Now</button> */}
                  </div>
                </div>
                <div className="d-flex align-items-center gap-15">
                  {/* <div>
                    <a href="">
                      <TbGitCompare className="fs-5 me-2" /> Add to Compare
                    </a>
                  </div> */}
                  <div>
                    <a href="">
                      <AiOutlineHeart className="fs-5 me-2" /> Ajouter dans favoris
                    </a>
                  </div>
                </div>
                <div className="d-flex gap-10 flex-column  my-3">
                  <h3 className="product-heading">Livraison et remboursement :</h3>
                  <p className="product-data">
                    Free shipping and returns available on all orders! <br /> We
                    ship all US domestic orders within
                    <b>5-10 business days!</b>
                  </p>
                </div>
                <div className="d-flex gap-10 align-items-center my-3">
                  <h3 className="product-heading">Lien du produit:</h3>
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      copyToClipboard(
                        window.location.href
                      );
                    }}
                  >
                    Copier le lien
                  </a>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </Container>
      <Container class1="description-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h4>Description</h4>
            <div className="bg-white p-3">
              <p  dangerouslySetInnerHTML={{__html: productState?.content}}>
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Container class1="reviews-wrapper home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 id="review">AVIS</h3>
            <div className="review-inner-wrapper">
              <div className="review-head d-flex justify-content-between align-items-end">
                <div>
                  <h4 className="mb-2">Avis des utilisareurs</h4>
                  <div className="d-flex align-items-center gap-10">
                    <ReactStars
                      count={5}
                      size={24}
                      value={productState?.totalrating}
                      edit={false}
                      activeColor="#ffd700"
                    />
                    <p className="mb-0">Base sur {productState?.rateProducts?.length} Reviews</p>
                  </div>
                </div>
                {orderedProduct && (
                  <div>
                    <a className="text-dark text-decoration-underline" href="">
                      Ecrire un avis
                    </a>
                  </div>
                )}
              </div>
              <div className="review-form py-4">
                <h4>Ecrire un avis</h4>
             
                  <div>
                    <ReactStars
                      count={5}
                      size={24}
                      value={4}
                      edit={true}
                      onChange={ (e)=> {
                        setStar(e)
                      }}
                      activeColor="#ffd700"
                    />
                  </div>
                  <div>
                    <textarea
                      name=""
                      id=""
                      className="w-100 form-control"
                      cols="30"
                      rows="4"
                      onChange={ (e)=> {
                        setComment(e.target.value)
                      }}
                      placeholder="Commentaire"
                    ></textarea>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <button className="button border-0" onClick={addRatingToProduct} type="button">Commenter</button>
                  </div>
              </div>
              <div className="reviews mt-4">
                {
                  productState && productState?.rateProducts?.map((item, index) => {
                    return (
<div className="review" key={index}>
                  <div className="d-flex gap-10 align-items-center">
                    <h6 className="mb-0">{item.user.username}</h6>
                    <ReactStars
                      count={5}
                      size={24}
                      value={item.rate}
                      edit={false}
                      activeColor="#ffd700"
                    />
                  </div>
                  <p className="mt-3">{item.comment}
                  </p>
                </div>
                    )
                  })
                
}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container class1="popular-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Produits liés à cet article</h3>
          </div>
        </div>
        <div className="row">
        {
            popularProduct && popularProduct?.map((item, index) => {
              if(item?.tags==='popular') {
                return (
                  <div key={item.id} className="col-md-3"
        >
          <div
            className="product-card position-relative"
          >
            <div className="wishlist-icon position-absolute">
              <button className="border-0 bg-transparent" onClick={(e) => {addToWish(item?.id)}}>
                <img src={wish} alt="wishlist" />
              </button>
            </div>
            <div className="product-image">
              <img src={`${login_url}${item?.imageUrl}`} className="img-fluid mx-auto" alt="product image" width={160} />
              <img src={`${login_url}${item?.imageUrl}`} className="img-fluid mx-auto" alt="product image" width={160} />
              {/* <img src={watch2} className="img-fluid" alt="product image" /> */}
            </div>
            <div className="product-details">
              <h6 className="brand">
                {/* {item.category.name} */}
                {item.brand.name}
                </h6>
              <h5 className="product-title">
               {item.title}
              </h5>
              <ReactStars
                count={5}
                size={24}
                value={item?.totalrating}
                edit={false}
                activeColor="#ffd700"
              />
           
              <p className="price">{item.price} Ar</p>
            </div>
            <div className="action-bar position-absolute">
              <div className="d-flex flex-column gap-15">
                {/* <button className="border-0 bg-transparent">
                  <img src={prodcompare} alt="compare" />
                </button> */}
                <button className="border-0 bg-transparent">
                <img onClick={ 
                  () => { navigate("/product/"+item?.slug)
                      dispatch(getAProduct(item?.slug))
                 }} src={view} alt="view pp" />
                </button>
                <button className="border-0 bg-transparent">
                  <img src={addcart} onClick={()=> { addToCart(item?.id, item?.title, 1,null,item?.price, item?.imageUrl) }} alt="addcart" />
                </button>
              </div>
            </div>
          </div>
        </div>
                )
              }
              
            })
          }
        </div>
      </Container>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header py-0 border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body py-0">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 w-50">
                  <img src={watch} className="img-fluid" alt="product imgae" />
                </div>
                <div className="d-flex flex-column flex-grow-1 w-50">
                  <h6 className="mb-3">Apple Watch</h6>
                  <p className="mb-1">Quantity: asgfd</p>
                  <p className="mb-1">Color: asgfd</p>
                  <p className="mb-1">Size: asgfd</p>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 py-0 justify-content-center gap-30">
              <button type="button" className="button" data-bs-dismiss="modal">
                View My Cart
              </button>
              <button type="button" className="button signup">
                Checkout
              </button>
            </div>
            <div className="d-flex justify-content-center py-3">
              <Link
                className="text-dark"
                to="/product"
                onClick={() => {
                  closeModal();
                }}
              >
                Continue To Shopping
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProduct;

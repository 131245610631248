import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import Container from "../components/Container";

const RefundPloicy = () => {
  return (
    <>
      <Meta title={"Remboursement"} />
      <BreadCrumb title="Remboursement" />
      <Container class1="policy-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="policy">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ac tellus fermentum neque volutpat semper a sit amet velit. Curabitur vitae vehicula dui, at dictum sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In hac habitasse platea dictumst. Nullam dapibus feugiat varius. Morbi at metus at orci sodales porta. Aliquam sit amet ipsum at elit pulvinar porta accumsan et eros. Vestibulum at pellentesque nunc, eu sollicitudin diam. Nunc ut ullamcorper diam, ut ullamcorper tellus. Curabitur vel urna ipsum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla sagittis mauris dapibus urna volutpat, nec varius eros posuere.

Curabitur congue mi urna, lacinia dictum neque maximus sit amet. Curabitur tristique ex sed fermentum aliquam. In placerat, orci ac faucibus tristique, sapien nibh pretium erat, nec suscipit elit nunc a nisi. Suspendisse volutpat, neque in condimentum egestas, leo dui hendrerit felis, a pretium urna enim eget massa. Quisque laoreet vestibulum risus, eget facilisis neque. Mauris ac suscipit elit, quis blandit ligula. In ultrices nunc at quam fermentum, eget finibus purus pretium. Nullam commodo sapien vel purus dictum cursus. Nulla diam orci, egestas nec risus vitae, gravida tincidunt nisi. In consequat lacinia nunc in pretium. Nullam gravida purus eu arcu gravida, eu volutpat arcu dignissim. Pellentesque finibus nulla eu lorem dignissim ornare. Etiam vehicula ex a est porttitor, nec commodo augue commodo. Quisque sed turpis justo.

Curabitur sed pretium magna. Vestibulum dictum nibh eu est convallis euismod. Donec mollis eu dui ac egestas. Nullam efficitur ex ante, eget sollicitudin arcu aliquet iaculis. Phasellus faucibus urna a ligula cursus, sit amet blandit elit dictum. Suspendisse et erat ut odio accumsan suscipit eu id diam. Mauris in ornare lacus, pretium facilisis tellus. Suspendisse et lobortis diam, eu efficitur justo. Cras in lobortis sem. Suspendisse quam quam, elementum eget tempus lacinia, vehicula iaculis mi. Nunc tempor venenatis lectus, in bibendum ipsum porttitor vel. Aliquam in venenatis felis. Praesent blandit vestibulum sem ut mattis. Curabitur quis risus et massa ultricies viverra in eget tortor. Vivamus luctus maximus ipsum quis efficitur.

Sed ac ex libero. Suspendisse laoreet lorem non mauris tempus, eu cursus massa pharetra. Praesent rhoncus felis non risus pellentesque, id lobortis nunc lobortis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean eu tempor quam. Sed fringilla est at sapien fermentum, lobortis tristique ipsum interdum. Phasellus dignissim elit eu posuere commodo. Vivamus a tincidunt tellus. Curabitur quis malesuada turpis. Etiam lacinia feugiat ligula, eget dapibus nunc sodales nec. Duis id nunc varius, pretium ipsum non, efficitur lacus. Nam id interdum nulla. Quisque vitae convallis sem. Praesent varius mauris a ante imperdiet, sit amet feugiat dui lacinia. Nullam bibendum ante vitae massa tincidunt imperdiet.

Nam eget massa justo. Suspendisse sit amet dui nec libero vehicula pretium vitae ac magna. Suspendisse efficitur sollicitudin ullamcorper. Sed gravida ultricies neque quis rutrum. Suspendisse urna sapien, lacinia porta mattis ac, placerat at leo. Suspendisse sodales eros eu condimentum dapibus. In ultrices turpis et lectus mattis, ac imperdiet lorem vulputate. Maecenas ante enim, vehicula at vestibulum quis, porta sagittis diam. Duis condimentum nec elit eget semper. Integer a sapien tortor. Fusce id scelerisque ligula, sollicitudin egestas eros. Etiam ut diam finibus, rhoncus lectus ut, dapibus felis. Vivamus vel vehicula erat. Nunc tempor arcu ac dolor efficitur commodo. Curabitur id mollis nunc, et imperdiet turpis.
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default RefundPloicy;

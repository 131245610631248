import React, { useEffect, useState } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import ReactStars from "react-rating-stars-component";
import ProductCard from "../components/ProductCard";
// import Color from "../components/Color";
import Container from "../components/Container";
import placeholderImg from "../images/placeholder.svg";
import gr4 from "../images/gr4.svg";
import gr3 from "../images/gr3.svg";
import gr2 from "../images/gr2.svg";
import gr from "../images/gr.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts } from "../features/products/productSlice";
import { array } from "yup";
import { Alert, Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Badge, Card, CardImg, Placeholder } from "react-bootstrap";
import { getCategory } from "../features/categories/categoriesSlice";

const OurStore = ({categoriesStateNav, cart, updateCart}) => {
  
  const location = useLocation();
  
  const [grid, setGrid] = useState(4);
  const navigate = useNavigate()
  const productState = useSelector((state) => state.product.product)
  const isLoading = useSelector((state) => state.product.isLoading)
  const [brands, setBrands] = useState(null)
  const [categories, setCategories] = useState(null)
  const [tags, setTags] = useState(null)
  const [sort, setSort] = useState(null)


  //Filter 
  const [category, setCategory] = useState(null)
  const [tag, setTag] = useState(null)
  const [brand, setBrand] = useState(null)
  const [minPrice, setMinPrice] = useState(null)
  const [maxPrice, setMaxPrice] = useState(null)
  const categoryParams= location.pathname.split('/')[2] ? location.pathname.split('/')[2]:null;
  const initFilter = ()=>{
    setCategory(null)
    setTag(null)
    setBrand(null)
    setMinPrice(null)
    setMaxPrice(null)
    navigate('/boutique')
  }
  const dispacth = useDispatch();
  useEffect(()=> {
    if(categoryParams!==null) {
      dispacth(getCategory(categoryParams)).then((data) => { 
       setCategory(data.payload)})
    }
    },[])
  useEffect(()=> {
    let newBrand=[];
    let category=[];
    let newtags=[];
    for(let index=0; index < productState.length; index++) {
      const element = productState[index]
      newBrand.push(element?.brand?.name)
      category.push(element?.category?.name)
      newtags.push(element.tags)
    }
    setBrands(newBrand)
    setCategories(category)
    setTags(newtags)
  },[productState])
  useEffect(()=> {
    getProducts();
  },[sort,tag,brand,category,minPrice,maxPrice]);
  const getProducts = () => {
   dispacth(getAllProducts({sort,tag,brand,category,minPrice,maxPrice}));
  };

  return (
    <>
      <Meta title={"Boutique"} />
      <BreadCrumb title="Boutique" />
      <Container class1="store-wrapper home-wrapper-2 py-5">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <div className="row">
          <div className="col-md-3">
            <div className="filter-card mb-3">
              <h3 className="filter-title">Categories</h3>
              <div>
                <ul className="ps-0">
                  {
                    categoriesStateNav && categoriesStateNav.map((item, index)=> {
                      return <li key={index} onClick={()=> {setCategory(item); navigate(`/boutique/${item.slug}`)}}>
                        {category===item.slug ? <Badge  bg="warning">{item.name}</Badge> :item.name}
                        </li>
                    })
                  }
                </ul>
              </div>
            </div>
            <div className="filter-card mb-3">
              <h3 className="filter-title">Filtre</h3>
              <div>
                <h5 className="sub-title">Prix</h5>
                <div className="d-flex align-items-center gap-10">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInput"
                      placeholder="From"
                      onBlur={(e)=> {
                        setMinPrice(e.target.value)
                      }}
                    />
                    <label htmlFor="floatingInput">De</label>
                  </div>
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInput1"
                      placeholder="To"
                      onBlur={(e)=> {
                        setMaxPrice(e.target.value)
                      }}
                    />
                    <label htmlFor="floatingInput1">a</label>
                  </div>
                </div>
              </div>
              <div className="mt-4 mb-3">
              <h3 className="">Produit Tags</h3>
              <div>
                <div className="product-tags d-flex flex-wrap align-items-center gap-10">
                {
                    tags && [...new Set(tags)].map((item, index)=> {
                      return <span className="text-capitalize badge bg-light text-secondary rounded-3 py-2 px-3" key={index} onClick={()=>setTag(item)}>{item}</span>
                    })
                  }
                </div>
              </div>
            </div>
            <div className=" mb-3">
              <h3 className="">Produit Marque</h3>
              <div>
                <div className="product-tags d-flex flex-wrap align-items-center gap-10">
                {
                    brands && [...new Set(brands)].map((item, index)=> {
                      return <span className="text-capitalize badge bg-light text-secondary rounded-3 py-2 px-3" key={index} onClick={()=>setBrand(item)}>{item}</span>
                    })
                  }
                  <Link to="/boutique" onClick={initFilter}>Effacer le filtre</Link>
                </div>
              </div>
            </div>
            </div>
            
            
          </div>
          <div className="col-md-9">
            <div className="filter-sort-grid mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-10">
                  <p className="mb-0 d-block" style={{ width: "100px" }}>
                    Trier par:
                  </p>
                  <select
                    name=""
                    defaultValue={"manula"}
                    className="form-control form-select"
                    id=""
                    onChange={(e)=> setSort(e.target.value)}
                  >
                    {/* <option value="manual">Featured</option>
                    <option value="best-selling">Best selling</option> */}
                    <option value="title-asc">de A-Z</option>
                    <option value="title-desc">de Z-A
                    </option>
                    <option value="price-asc">Prix le plus bas</option>
                    <option value="price-desc">Price le plus haut</option>
                    <option value="createdAt-asc">Recent</option>
                    <option value="-createdAt-desc">Ancien</option>
                  </select>
                </div>
                <div className="d-flex align-items-center gap-10">
                  <p className="totalproducts mb-0">{productState?.length > 0 ? `${productState?.length} Produits` : 'Aucun produit'}</p>
                  <div className="d-flex gap-10 align-items-center grid">
                    <img
                      onClick={() => {
                        setGrid(3);
                      }}
                      src={gr4}
                      className="d-block img-fluid"
                      alt="grid"
                    />
                    <img
                      onClick={() => {
                        setGrid(4);
                      }}
                      src={gr3}
                      className="d-block img-fluid"
                      alt="grid"
                    />
                    <img
                      onClick={() => {
                        setGrid(6);
                      }}
                      src={gr2}
                      className="d-block img-fluid"
                      alt="grid"
                    />

                    <img
                      onClick={() => {
                        setGrid(12);
                      }}
                      src={gr}
                      className="d-block img-fluid"
                      alt="grid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="products-list pb-5">
              <div className="d-flex gap-10 flex-wrap">
                { isLoading ?
                <>
                <Card className="col-md-3">
        <CardImg variant="top" src={placeholderImg} />
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> <Placeholder xs={8} />
          </Placeholder>
          {/* <Placeholder.Button variant="primary" xs={6} /> */}
        </Card.Body>
      </Card><Card className="col-md-3">
        <CardImg variant="top" src={placeholderImg} />
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> <Placeholder xs={8} />
          </Placeholder>
          {/* <Placeholder.Button variant="primary" xs={6} /> */}
        </Card.Body>
      </Card><Card className="col-md-3">
        <CardImg variant="top" src={placeholderImg} />
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> <Placeholder xs={8} />
          </Placeholder>
          {/* <Placeholder.Button variant="primary" xs={6} /> */}
        </Card.Body>
      </Card> </> :
           productState?.length===0 ? <Alert severity="info">Aucun produit correspond a votre recherche</Alert> :
                  <ProductCard data={productState ? productState : []} grid={grid} cart={cart} updateCart={updateCart} />}
      
                
                
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default OurStore;

import axios from "axios";
import { base_url, config, config_init } from "../../utils/axiosConfig";

const getCategories=async()=> {
    const response = await axios.get(`${base_url}categories/`);
    if(response.data) {
        return response.data;
    }
}

const getCategoriesNav=async()=> {
    const response = await axios.get(`${base_url}get-category/`);
    if(response.data) {
        return response.data;
    }
}
const getACategory=async(slug)=> {
    const response = await axios.get(`${base_url}category/${slug}`);
    if(response.data) {
        return response.data;
    }
}

export const categoriesService={
    getCategories,
    getCategoriesNav,
    getACategory,
}
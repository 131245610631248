import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <div className="div">
            <h2>Page non trouvee</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum assumenda, animi, hic reiciendis odio esse minus dolor nisi vitae iusto laboriosam quis asperiores nobis harum eum explicabo, illum amet aliquam!</p>
            <p>retourner a la <Link to="/">page d'accueil</Link> </p>
        </div>
    )
}
import React from "react";

const Color = (props) => {
  const {data, setColor} = props;
  return (
    <>
      <ul className="colors ps-0">
      {
      data?.map((item, index) => {
                return (
                  <li onClick={() => setColor(item?.id)} key={index}  style={{background:item?.value}}></li>
                )
              
            })
          }
      </ul>
    </>
  );
};

export default Color;
